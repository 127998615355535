import { AuthViaAuthenticationScope, authViaLineIntegration } from '@/models/platform-authVia';
import { buildPlatformAuthenticatedHeader, fetchWithIntercept } from '.';

const platformApiHost: string = process.env.VUE_APP_ACXESS_API_URL!;

export async function authenticateMerchant(scope: AuthViaAuthenticationScope): Promise<string> {
  const result = await fetchWithIntercept(`${platformApiHost}/authVia/authenticate-merchant`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildPlatformAuthenticatedHeader(),
      },
      body: JSON.stringify({
        scope,
      }),
    });
  if (result.success) {
    return result.token;
  }
  throw Error('Error authenticating merchant.');
}

export async function getAuthViaLineIntegrations(customerLineIds: Array<string>): Promise<Array<authViaLineIntegration>> {
  const result = await fetchWithIntercept(`${platformApiHost}/authVia/get-line-integrations`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildPlatformAuthenticatedHeader(),
      },
      body: JSON.stringify({
        customerLineIds,
      }),
    });
  if (result.success) {
    return result.authViaLineIntegrations;
  }
  return [] as Array<authViaLineIntegration>;
}
