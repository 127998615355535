import {
  acxessForgotPasswordResult,
  acxessResetPasswordResult,
  acxessValidatePasswordResetTokenResult,
  acxessValidateSignupTokenResult,
  acxessLine,
  acxessSession,
  acxessUser,
  axcessInviteRequest,
  acxessRole,
  acxessKeywordResponder,
  acxessSaveKeywordResponderResult,
  acxessAutoResponder,
  acxessContact,
  acxessContactList,
  acxessPagedResponse,
  acxessSignupResult,
  acxessContactSortBy,
  acxessSortDirection,
  acxessAssignLine,
  acxessResponseTemplate,
  acxessUpdateContactListNameResult,
  acxessUploadAttachmentsResponse,
  acxessAddContactList,
  acxessEditUser,
  acxessUpdateUserPassword,
  acxessLogoutResult,
  acxessBillingPlan,
  acxessMessagePlanUsage,
  acxessAccountSendWindowResponse,
  acxessContactImportContent,
  acxessLineIntegrationPlatformAppOptions,
  acxessUserSignature,
  accountSettings,
  updateAccountSettings,
  saveAccountCustomContactFieldContent,
  accountCustomContactField,
  saveCustomContactFieldContent,
  messagePlanAndUsage,
  userPlanAndUsage,
  acxessProductIds,
} from '@/models/acxess';
import {
  acxessReportCategoryType,
  acxessReport,
  acxessReportData,
  acxessGraph,
  dashboardBigNumbers,
  reportPeriodType,
} from '@/models/reporting';
import initializeTwoFactorResponse from '@/models/user/initializeTwoFactorSetupResponse';
import globalStore from '@/store/index';
import AcxessException from '@/models/exceptions/acxessException';
import getUserTwoFactorResponse from '@/models/user/getUserTwoFactorMethodsResponse';
import { isNullOrEmpty } from '@/utils';
import { fetchWithIntercept, invalidateServiceCache } from '.';

const acxessApiHost: string = process.env.VUE_APP_ACXESS_API_URL!;
const getUsersApiUrl = `${acxessApiHost}/user/account-users`;
const getContactsApiUrl = `${acxessApiHost}/contact/search-contacts`;
const getContactListsApiUrl = `${acxessApiHost}/contact/get-account-contact-lists`;
const getAllResponseTemplatesUrl = `${acxessApiHost}/responder/response-templates-for-account`;
const getAccountSendWindowUrl = `${acxessApiHost}/account/account-send-window`;
const generateBigNumberStatsUrl = `${acxessApiHost}/report/generate-big-number-stats`;

export function buildAcxessAuthenticatedHeader(): any {
  if (globalStore.getters['auth/token'].trim().length === 0) {
    // no token set, call will result in a redirect to login in fetchWithIntercept
    return {};
  }
  return {
    Accept: 'application/json',
    Authorization: `Bearer ${globalStore.getters['auth/token']}`,
  };
}

export async function login(email: string, password: string, twoFactorCode: string, twoFactorMethodType: number, rememberDevice: boolean): Promise<acxessSession> {
  const result: acxessSession = await fetchWithIntercept(`${acxessApiHost}/user/login`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email, password, twoFactorCode, twoFactorMethodType, rememberDevice,
    }),
  });
  if (result != null) {
    return result;
  }
  throw Error('login failed');
}

export async function logout(): Promise<acxessLogoutResult> {
  const result = await fetchWithIntercept(`${acxessApiHost}/user/logout`, {
    method: 'POST',
    headers: buildAcxessAuthenticatedHeader(),
  });
  if (result.success) {
    return result;
  }
  throw Error('logout failed');
}

export async function forgotPassword(email: string): Promise<acxessForgotPasswordResult> {
  const result: acxessForgotPasswordResult = await fetchWithIntercept(`${acxessApiHost}/user/forgot-password`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email }),
  });
  if (result != null) {
    return result;
  }
  throw Error('forgot password failed');
}

export async function resetPassword(email: string, password: string, passwordResetToken: string): Promise<acxessResetPasswordResult> {
  const result: acxessResetPasswordResult = await fetchWithIntercept(`${acxessApiHost}/user/reset-password`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, password, passwordResetToken }),
  });
  if (result != null) {
    return result;
  }
  throw Error('reset password failed');
}

export async function validateForgotPasswordResetToken(email: string, passwordResetToken: string): Promise<acxessValidatePasswordResetTokenResult> {
  const result: acxessValidatePasswordResetTokenResult = await fetchWithIntercept(`${acxessApiHost}/user/validate-forgot-password-reset-token`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, passwordResetToken }),
  });
  if (result != null) {
    return result;
  }
  throw Error('password reset token validation failed');
}

export async function validateSignupToken(email: string, token: string): Promise<acxessValidateSignupTokenResult> {
  const result: acxessValidateSignupTokenResult = await fetchWithIntercept(`${acxessApiHost}/user/validate-signup`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, token }),
  });
  if (result != null) {
    return result;
  }
  throw Error('signup token validation failed');
}

export async function refreshUserToken(): Promise<string> {
  const result = await fetchWithIntercept(`${acxessApiHost}/user/refresh-user-token`, {
    method: 'GET',
    headers: buildAcxessAuthenticatedHeader(),
  });
  if (result.success) {
    return result.token;
  }
  throw Error('failed to refresh token');
}

export async function getAccountTimezone(): Promise<string> {
  const result = await fetchWithIntercept(`${acxessApiHost}/account/account-timezone`, {
    method: 'GET',
    headers: buildAcxessAuthenticatedHeader(),
  });
  if (result.success) {
    return result.timezone;
  }
  throw Error('failed to get account timezone');
}

export async function getAccountSendWindow(utc: boolean): Promise<acxessAccountSendWindowResponse> {
  const result = await fetchWithIntercept(getAccountSendWindowUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...buildAcxessAuthenticatedHeader(),
    },
    body: JSON.stringify({ utc }),
  });
  if (result.success) {
    return result;
  }
  throw Error('failed to get account send window');
}

export async function updateAccountTimezone(timezone: string): Promise<boolean> {
  const result = await fetchWithIntercept(`${acxessApiHost}/account/update-account-timezone`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...buildAcxessAuthenticatedHeader(),
    },
    body: JSON.stringify({
      Timezone: timezone,
    }),
  });
  if (result.success) {
    return result.success;
  }
  throw Error('failed to update account timezone');
}

export async function getAccountSettings(): Promise<accountSettings> {
  const result = await fetchWithIntercept(`${acxessApiHost}/account/account-settings`, {
    method: 'GET',
    headers: buildAcxessAuthenticatedHeader(),
  });
  if (result.success) {
    return result;
  }
  throw Error('failed to get account settings');
}

export async function updateAccountSettings(settings: updateAccountSettings): Promise<boolean> {
  const result = await fetchWithIntercept(`${acxessApiHost}/account/account-settings`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      ...buildAcxessAuthenticatedHeader(),
    },
    body: JSON.stringify({
      Timezone: settings.timezone,
      SendWindowStart: settings.sendWindowStart,
      SendWindowEnd: settings.sendWindowEnd,
      TwoFactorRequired: settings.twoFactorRequired,
      ConversationAutoCloseMinutes: settings.conversationAutoCloseMinutes,
    }),
  });
  if (result.success) {
    return result.success;
  }
  throw Error('failed to update account settings');
}

export async function inviteUsers(request: axcessInviteRequest): Promise<boolean> {
  const res = await fetchWithIntercept(`${acxessApiHost}/user/invite-users`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...buildAcxessAuthenticatedHeader(),
    },
    body: JSON.stringify(request),
  });
  if (res.success) {
    invalidateServiceCache(getUsersApiUrl);
    return true;
  }
  throw Error('invite failed');
}

export async function reinviteUser(userPublicId: number): Promise<boolean> {
  const res = await fetchWithIntercept(`${acxessApiHost}/user/reinvite-user`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...buildAcxessAuthenticatedHeader(),
    },
    body: JSON.stringify({
      UserPublicId: userPublicId,
    }),
  });
  if (res.success) {
    invalidateServiceCache(getUsersApiUrl);
    return true;
  }
  throw Error('reinvite failed');
}

export async function editUser(request: acxessEditUser): Promise<boolean> {
  const res = await fetchWithIntercept(`${acxessApiHost}/user/user/${request.publicId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      ...buildAcxessAuthenticatedHeader(),
    },
    body: JSON.stringify(request),
  });
  if (res.success) {
    invalidateServiceCache(getUsersApiUrl);
    return true;
  }
  throw Error('update user failed');
}

export async function updateUserPassword(request: acxessUpdateUserPassword): Promise<boolean> {
  const res = await fetchWithIntercept(`${acxessApiHost}/user/user/${request.publicId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      ...buildAcxessAuthenticatedHeader(),
    },
    body: JSON.stringify(request),
  });
  return res.success;
}

export async function hasExistingApiKey(): Promise<boolean> {
  const res = await fetchWithIntercept(`${acxessApiHost}/account/existing-api-key/`, {
    method: 'GET',
    headers: buildAcxessAuthenticatedHeader(),
  });
  return res.hasExistingApiKey;
}

export async function signup(email: string, firstName: string, lastName: string, password: string, token: string): Promise<acxessSignupResult> {
  const result: acxessSignupResult = await fetchWithIntercept(`${acxessApiHost}/user/complete-signup`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      firstName,
      lastName,
      password,
      token,
    }),
  });
  if (result.success) {
    return result;
  }
  throw Error('signup failed');
}

export async function getMessageBillingPlan(): Promise<acxessBillingPlan> {
  const res = await fetchWithIntercept(`${acxessApiHost}/billing/billing-plan-message`, {
    method: 'GET',
    headers: buildAcxessAuthenticatedHeader(),
  }, true);
  if (res.isActive) {
    return {
      planType: res.planType,
      billingTerm: res.billingTerm,
      planCount: res.planCount ?? -1,
      billingDay: res.billingDay,
      startDate: res.startDate,
      endDate: res.endDate,
      isActive: res.isActive,
      currentBillingWindowStart: res.currentBillingWindowStart,
      currentBillingWindowEnd: res.currentBillingWindowEnd,
    };
  }
  throw Error('error retrieving message billing plan');
}

export async function getUserBillingPlan(): Promise<acxessBillingPlan> {
  const res = await fetchWithIntercept(`${acxessApiHost}/billing/billing-plan-user`, {
    method: 'GET',
    headers: buildAcxessAuthenticatedHeader(),
  }, true);
  if (res.isActive) {
    return {
      planType: res.planType,
      billingTerm: res.billingTerm,
      planCount: res.planCount,
      billingDay: res.billingDay,
      startDate: res.startDate,
      endDate: res.endDate,
      isActive: res.isActive,
      currentBillingWindowStart: res.currentBillingWindowStart,
      currentBillingWindowEnd: res.currentBillingWindowEnd,
    };
  }
  throw Error('error retrieving user billing plan');
}

export async function getMessagePlanUsage(): Promise<acxessMessagePlanUsage> {
  const res = await fetchWithIntercept(`${acxessApiHost}/billing/plan-usage-message`, {
    method: 'GET',
    headers: buildAcxessAuthenticatedHeader(),
  }, true);
  if (res.success) {
    return {
      used: res.usedCount,
      scheduled: res.scheduledCount,
      total: res.usedCount + res.scheduledCount,
    };
  }
  throw Error('error retrieving message plan usage');
}

export async function getUserPlanUsage(): Promise<number> {
  const res = await fetchWithIntercept(`${acxessApiHost}/billing/plan-usage-user`, {
    method: 'GET',
    headers: buildAcxessAuthenticatedHeader(),
  }, true);
  if (res.success) {
    return res.userCount;
  }
  throw Error('error retrieving message plan usage');
}

export async function getMessagePlanAndUsage(): Promise<messagePlanAndUsage> {
  const res = await fetchWithIntercept(`${acxessApiHost}/billing/message-plan-and-usage`, {
    method: 'GET',
    headers: buildAcxessAuthenticatedHeader(),
  }, true);
  if (res.success) {
    return {
      billingPlan: res.billingPlan,
      used: res.usedCount,
      scheduled: res.scheduledCount,
      total: res.usedCount + res.scheduledCount,
    };
  }
  throw Error('error retrieving message plan');
}

export async function getUserPlanAndUsage(): Promise<userPlanAndUsage> {
  const res = await fetchWithIntercept(`${acxessApiHost}/billing/user-plan-and-usage`, {
    method: 'GET',
    headers: buildAcxessAuthenticatedHeader(),
  }, true);
  if (res.success) {
    return {
      billingPlan: res.billingPlan,
      userCount: res.userCount,
    };
  }
  throw Error('error retrieving user plan');
}

export async function getUsers(): Promise<Array<acxessUser>> {
  const res = await fetchWithIntercept(getUsersApiUrl, {
    method: 'GET',
    headers: buildAcxessAuthenticatedHeader(),
  }, true);
  return res.users;
}

export async function getLines(): Promise<Array<acxessLine>> {
  const res = await fetchWithIntercept(`${acxessApiHost}/line/get-account-lines`,
    {
      method: 'GET',
      headers: buildAcxessAuthenticatedHeader(),
    }, true);
  return res.customerLines;
}

export async function getUserLines(productIds: Array<acxessProductIds> = []): Promise<Array<acxessLine>> {
  let url = `${acxessApiHost}/line/get-user-lines`;
  if (productIds.length > 0) {
    productIds.forEach((x, index) => {
      const querySymbol = index === 0 ? '?' : '&';
      url = url.concat(`${querySymbol}productIds=${x}`);
    });
  }
  const res = await fetchWithIntercept(url,
    {
      method: 'GET',
      headers: buildAcxessAuthenticatedHeader(),
    }, true);
  return res.customerLines;
}

export async function getLinesWithUsers(request: { LineIds: Array<string> }): Promise<Array<acxessAssignLine>> {
  const res = await fetchWithIntercept(`${acxessApiHost}/line/users-assigned-to-lines`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...buildAcxessAuthenticatedHeader(),
    },
    body: JSON.stringify(request),
  });
  if (res.success) {
    return res.lines;
  }
  throw Error('get lines with users failed');
}

export async function getLinesWithStandardUsers(request: { LineIds: Array<string> }): Promise<Array<acxessAssignLine>> {
  const res = await fetchWithIntercept(`${acxessApiHost}/line/standard-users-assigned-to-lines`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...buildAcxessAuthenticatedHeader(),
    },
    body: JSON.stringify(request),
  });
  if (res.success) {
    return res.lines;
  }
  throw Error('get lines with standard users failed');
}

export async function updateLineAccess(request: acxessAssignLine): Promise<Array<acxessLine>> {
  const res = await fetchWithIntercept(`${acxessApiHost}/line/update-line-access`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...buildAcxessAuthenticatedHeader(),
    },
    body: JSON.stringify(request),
  });
  if (res.success) {
    return res.lines;
  }
  throw Error('get lines with users failed');
}

export async function getKeywordResponders(): Promise<Array<acxessKeywordResponder>> {
  const res = await fetchWithIntercept(`${acxessApiHost}/responder/keyword-responders-for-account`,
    {
      method: 'GET',
      headers: buildAcxessAuthenticatedHeader(),
    });
  return res.responders;
}

export async function getKeywordResponder(request: number): Promise<acxessKeywordResponder> {
  const res = await fetchWithIntercept(`${acxessApiHost}/responder/keyword-responder/${request}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
    });
  return res.responder;
}

export async function keywordResponderEnabledLines(): Promise<Array<acxessLine>> {
  const res = await fetchWithIntercept(`${acxessApiHost}/responder/keyword-responder-enabled-lines`,
    {
      method: 'GET',
      headers: buildAcxessAuthenticatedHeader(),
    }, true);
  return res.lines;
}

export async function addKeywordResponder(request: acxessKeywordResponder): Promise<acxessSaveKeywordResponderResult> {
  const res = await fetchWithIntercept(`${acxessApiHost}/responder/add-keyword-responder`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...buildAcxessAuthenticatedHeader(),
    },
    body: JSON.stringify(request),
  });
  if (res.success) {
    return {
      message: res.message,
      savedResponder: res.success,
      addedAllLines: res.lineAssignmentSuccessful,
      keywordResponder: res.keywordResponder,
    } as acxessSaveKeywordResponderResult;
  }
  throw Error('add keyword failed');
}

export async function updateKeywordResponder(request: acxessKeywordResponder): Promise<acxessSaveKeywordResponderResult> {
  const res = await fetchWithIntercept(`${acxessApiHost}/responder/keyword-responder/${request.publicId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      ...buildAcxessAuthenticatedHeader(),
    },
    body: JSON.stringify(request),
  });
  if (res.success) {
    return {
      message: res.message,
      savedResponder: res.success,
      addedAllLines: res.lineAssignmentSuccessful,
    } as acxessSaveKeywordResponderResult;
  }
  throw Error('update keyword failed');
}

export async function getAutoResponders(): Promise<Array<acxessAutoResponder>> {
  const res = await fetchWithIntercept(`${acxessApiHost}/responder/account-auto-responders`,
    {
      method: 'GET',
      headers: buildAcxessAuthenticatedHeader(),
    });
  return res.autoResponders;
}

export async function getAutoResponder(request: number): Promise<acxessAutoResponder> {
  const res = await fetchWithIntercept(`${acxessApiHost}/responder/auto-responder/${request}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
    });
  return res.autoResponder;
}

export async function autoResponderEnabledLines(): Promise<Array<acxessLine>> {
  const res = await fetchWithIntercept(`${acxessApiHost}/responder/auto-responder-enabled-lines`,
    {
      method: 'GET',
      headers: buildAcxessAuthenticatedHeader(),
    }, true);
  return res.lines;
}

export async function addAutoResponder(request: acxessAutoResponder): Promise<acxessAutoResponder> {
  const res = await fetchWithIntercept(`${acxessApiHost}/responder/create-auto-responder`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...buildAcxessAuthenticatedHeader(),
    },
    body: JSON.stringify(request),
  });
  if (res.success) {
    return res.autoResponder;
  }
  throw Error('add auto responder failed');
}

export async function updateAutoResponder(request: acxessAutoResponder): Promise<boolean> {
  const res = await fetchWithIntercept(`${acxessApiHost}/responder/auto-responder/${request.publicId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      ...buildAcxessAuthenticatedHeader(),
    },
    body: JSON.stringify(request),
  });
  if (res.success) {
    return true;
  }
  throw Error('update keyword failed');
}

export async function deleteResponder(request: number): Promise<boolean> {
  const res = await fetchWithIntercept(`${acxessApiHost}/responder/responder/${request}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      ...buildAcxessAuthenticatedHeader(),
    },
  });
  if (res.success) {
    return true;
  }
  throw Error('delete responder failed');
}

export async function getRoles(): Promise<Array<acxessRole>> {
  // TODO check globalStore for existing roles.. if non exists fetch and fill the store (i.e. cache)
  return [{
    name: 'Standard',
    id: 1,
  }, {
    name: 'Admin',
    id: 2,
  }, {
    name: 'Integration',
    id: 3,
  }];
}

export async function getAssignableRoles(): Promise<Array<acxessRole>> {
  return [{
    name: 'Standard',
    id: 1,
  }, {
    name: 'Admin',
    id: 2,
  }];
}

export async function getContact(contactid: string): Promise<acxessContact> {
  const res = await fetchWithIntercept(`${acxessApiHost}/contact/contact/${contactid}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
    });
  if (res.success) {
    return res.contact;
  }
  throw Error('get contact failed.');
}

export async function saveContact(contact: acxessContact, contactListIdsToAdd: Array<string>, contactListIdsToRemove: Array<string>, customContactFields: Array<saveCustomContactFieldContent>): Promise<Array<boolean>> {
  const res = await fetchWithIntercept(`${acxessApiHost}/contact/save-contact`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify({
        MobileNumber: contact.mobileNumber,
        FirstName: contact.firstName,
        LastName: contact.lastName,
        Email: contact.email,
        Note: contact.note,
        ContactListIdsToAdd: contactListIdsToAdd,
        ContactListIdsToRemove: contactListIdsToRemove,
        CustomContactFields: customContactFields,
        IsBlocked: contact.isBlocked,
      }),
    });
  if (res.success) {
    invalidateServiceCache(getContactsApiUrl);
    return res.success;
  }
  throw Error('save contact failed.');
}

export async function queueImportContacts(request: any): Promise<any> {
  const formData = new FormData();
  formData.append('ColumnMapping[MobileNumber]', request.columnMapping.MobileNumber);
  if (request.columnMapping.FirstName != null) {
    formData.append('ColumnMapping[FirstName]', request.columnMapping.FirstName);
  }
  if (request.columnMapping.LastName != null) {
    formData.append('ColumnMapping[LastName]', request.columnMapping.LastName);
  }
  if (request.columnMapping.Email != null) {
    formData.append('ColumnMapping[Email]', request.columnMapping.Email);
  }
  if (request.columnMapping.Note != null) {
    formData.append('ColumnMapping[Note]', request.columnMapping.Note);
  }
  request.accountCustomContactFieldMaps.forEach((x: any) => {
    formData.append(`ColumnMapping[${x.fieldName}]`, x.map);
  });
  if (request.contactListPublicIds.length > 0) {
    for (let i = 0; i < request.contactListPublicIds.length; i++) {
      formData.append('ContactListPublicIds', request.contactListPublicIds[i]);
    }
  }
  formData.append('ImportFile', request.importFile);
  formData.append('ContactImportType', request.contactImportType);

  const res = await fetchWithIntercept(`${acxessApiHost}/contact/queue-contact-import`,
    {
      method: 'POST',
      headers: {
        ...buildAcxessAuthenticatedHeader(),
      },
      body: formData,
    });
  invalidateServiceCache(getContactsApiUrl);
  invalidateServiceCache(getContactListsApiUrl);
  return {
    message: res.message,
    success: res.success,
    invalidContactCount: res.invalidContactCount,
    errorFilePath: res.errorFilePath,
    contactImportId: res.contactImportId,
  };
}

export async function queueImportContactConsent(request: any): Promise<any> {
  const formData = new FormData();
  formData.append('ColumnMapping[MobileNumber]', request.columnMapping.MobileNumber);
  if (request.columnMapping.Status != null) {
    formData.append('ColumnMapping[Status]', request.columnMapping.Status);
  }
  formData.append('ImportFile', request.importFile);
  formData.append('ContactImportType', request.contactImportType);
  formData.append('OutboundLineId', request.outboundLineId);

  const res = await fetchWithIntercept(`${acxessApiHost}/contact/queue-contact-consent`,
    {
      method: 'POST',
      headers: {
        ...buildAcxessAuthenticatedHeader(),
      },
      body: formData,
    });
  invalidateServiceCache(getContactsApiUrl);
  return {
    message: res.message,
    success: res.success,
    invalidContactCount: res.invalidContactCount,
    errorFilePath: res.errorFilePath,
    contactImportId: res.contactImportId,
  };
}

export async function downloadImportErrorFile(request: { url: string, downloadableFileName: string }): Promise<any> {
  const res = await fetchWithIntercept(`${acxessApiHost}/contact/download-import-error-file`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify(request),
    });
  return res.file;
}

export async function getContacts(searchTerm: string, page: number, pageSize: number, sortBy: acxessContactSortBy, sortDirection: acxessSortDirection): Promise<acxessPagedResponse<Array<acxessContact>>> {
  const res = await fetchWithIntercept(getContactsApiUrl,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify({
        SearchTerm: searchTerm,
        Page: page,
        ResultsPerPage: pageSize,
        SortBy: sortBy,
        Direction: sortDirection,
      }),
    });
  if (res.success) {
    return {
      message: res.message,
      page: res.page,
      payload: res.contactResults,
      success: res.success,
      totalPages: res.totalPages,
      totalItems: res.totalContacts,
    };
  }
  throw Error('Failed to get contacts.');
}

export async function getAllContacts(searchTerm: string, page: number, pageSize: number, sortBy: acxessContactSortBy, sortDirection: acxessSortDirection): Promise<acxessPagedResponse<Array<acxessContact>>> {
  const res = await fetchWithIntercept(getContactsApiUrl,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify({
        SearchTerm: searchTerm,
        Page: page,
        ResultsPerPage: pageSize,
        SortBy: sortBy,
        Direction: sortDirection,
      }),
    }, isNullOrEmpty(searchTerm));
  if (res.success) {
    return {
      message: res.message,
      page: res.page,
      payload: res.contactResults,
      success: res.success,
      totalPages: res.totalPages,
      totalItems: res.totalContacts,
    };
  }
  throw Error('Failed to get all contacts.');
}

export async function getResponseTemplateEnabledLines(): Promise<Array<acxessLine>> {
  const res = await fetchWithIntercept(`${acxessApiHost}/responder/response-template-enabled-lines`,
    {
      method: 'GET',
      headers: buildAcxessAuthenticatedHeader(),
    }, true);
  return res.lines;
}

export async function getResponseTemplates(): Promise<Array<acxessResponseTemplate>> {
  const res = await fetchWithIntercept(getAllResponseTemplatesUrl,
    {
      method: 'GET',
      headers: buildAcxessAuthenticatedHeader(),
    }, true);
  return res.responseTemplates;
}

export async function getResponseTemplate(publicId: number): Promise<acxessResponseTemplate> {
  const res = await fetchWithIntercept(`${acxessApiHost}/responder/response-template/${publicId}`,
    {
      method: 'GET',
      headers: buildAcxessAuthenticatedHeader(),
    });
  return res.responseTemplate;
}

export async function saveResponseTemplate(template: acxessResponseTemplate, addedLines: Array<acxessLine>, removedLines: Array<acxessLine>): Promise<acxessResponseTemplate> {
  // get all lines and compare with selected for diff
  const delLineIds: Array<string> = removedLines.map((x) => x.lineId);
  const saveLineIds: Array<string> = addedLines.map((x) => x.lineId);
  if (template.publicId != null) {
    // updating
    const resUpdate = await fetchWithIntercept(`${acxessApiHost}/responder/response-template/${template.publicId}`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          ...buildAcxessAuthenticatedHeader(),
        },
        body: JSON.stringify({
          Name: template.name,
          Message: template.message,
          AttachmentUrl: template.attachmentUrl,
          IsEnabled: template.isEnabled,
          LineIds: saveLineIds,
          RemoveLineIds: delLineIds,
        }),
      });
    if (resUpdate.success) {
      invalidateServiceCache(getAllResponseTemplatesUrl);
      return template;
    }
    throw new AcxessException(`error saving repsonse template: ${resUpdate.message}`);
  }
  // new template
  const resNew = await fetchWithIntercept(`${acxessApiHost}/responder/add-response-template`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify({
        Name: template.name,
        Message: template.message,
        AttachmentUrl: template.attachmentUrl,
        LineIds: saveLineIds,
      }),
    });
  if (resNew.success) {
    invalidateServiceCache(getAllResponseTemplatesUrl);
    return resNew.responseTemplate;
  }
  throw new AcxessException(`error saving repsonse template: ${resNew.message}`);
}

export async function deleteResponseTemplate(publicId: number): Promise<void> {
  const res = await fetchWithIntercept(`${acxessApiHost}/responder/response-template/${publicId}`,
    {
      method: 'DELETE',
      headers: buildAcxessAuthenticatedHeader(),
    });
  invalidateServiceCache(getAllResponseTemplatesUrl);
}

export async function getContactLists(): Promise<Array<acxessContactList>> {
  const res = await fetchWithIntercept(getContactListsApiUrl,
    {
      method: 'GET',
      headers: buildAcxessAuthenticatedHeader(),
    }, true);
  return res?.contactLists ?? [];
}

export async function getContactList(publicId: number): Promise<acxessContactList> {
  const res = await fetchWithIntercept(`${acxessApiHost}/contact/contact-list/${publicId}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
    });
  return {
    id: res.contactList.id,
    publicId: res.contactList.publicId,
    name: res.contactList.name,
    contactCount: res.contactList.contactCount,
    isEnabled: res.contactList.isEnabled,
    isSystemGenerated: res.contactList.isSystemGenerated,
    isDeleted: res.contactList.isDeleted,
    created: res.contactList.created,
    contacts: res.contacts,
  };
}

export async function updateContactList(request: acxessContactList): Promise<acxessUpdateContactListNameResult> {
  const res = await fetchWithIntercept(`${acxessApiHost}/contact/update-contact-list`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify(request),
    });
  return {
    message: res.message,
    success: res.success,
  };
}

export async function addContactList(request: acxessAddContactList): Promise<acxessContactList> {
  const res = await fetchWithIntercept(`${acxessApiHost}/contact/create-contact-list`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify(request),
    });
  return res.contactList;
}

export async function deleteContactList(request: number): Promise<boolean> {
  const res = await fetchWithIntercept(`${acxessApiHost}/contact/soft-delete-contact-list/${request}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      ...buildAcxessAuthenticatedHeader(),
    },
  });
  if (res.success) {
    return true;
  }
  throw Error('delete contact list failed');
}

export async function updateContactListStatus(request: { id: string, isEnabled: boolean }): Promise<boolean> {
  const res = await fetchWithIntercept(`${acxessApiHost}/contact/update-contact-list`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify(request),
    });
  if (res.success) {
    return true;
  }
  throw Error('Failed to change contact list status.');
}

export async function attachmentUpload(files: Array<File>): Promise<Array<acxessUploadAttachmentsResponse>> {
  const form: FormData = new FormData();
  for (let i = 0; i < files.length; i++) {
    form.append('Attachments', files[i]);
  }
  const res = await fetchWithIntercept(`${acxessApiHost}/Attachment/upload-attachments`,
    {
      method: 'POST',
      headers: {
        ...buildAcxessAuthenticatedHeader(),
      },
      body: form,
    });
  if (res.success) {
    return res.attachments;
  }
  throw Error(`Error uploading attachments. Message:${res.message ?? 'n/a'}`);
}

export async function exportContactList(request: { contactListPublicId: number }): Promise<string> {
  const res = await fetchWithIntercept(`${acxessApiHost}/contact/export-contacts`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify(request),
    });
  if (res.success) {
    return res.exportFileUrl;
  }
  throw Error(`Error exporting contact list. Message:${res.message ?? 'n/a'}`);
}

export async function exportContacts(request: { searchTerm: string }): Promise<string> {
  const res = await fetchWithIntercept(`${acxessApiHost}/contact/export-contacts`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify(request),
    });
  if (res.success) {
    return res.exportFileUrl;
  }
  throw Error(`Error exporting contacts. Message:${res.message ?? 'n/a'}`);
}

export async function retrieveExportFile(request: { url: string, downloadableFileName: string }): Promise<any> {
  const res = await fetchWithIntercept(`${acxessApiHost}/contact/download-export-file`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify(request),
    });
  if (res.success) {
    return res.file;
  }
  throw Error(`Error downloading file. Message:${res.message ?? 'n/a'}`);
}

export async function getContactOptStatuses(phoneNumber: string): Promise<any> {
  const res = await fetchWithIntercept(`${acxessApiHost}/line/get-contact-opt-statuses`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify({
        ContactNumber: phoneNumber,
      }),
    });
  if (res.success) {
    return res.customerLinesWithStatus;
  }
  throw Error(`Error retrieving contact opt statuses. Message:${res.message ?? 'n/a'}`);
}

export async function softDeleteUser(publicId: number): Promise<boolean> {
  const res = await fetchWithIntercept(`${acxessApiHost}/user/soft-delete-user/${publicId}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
    });
  if (res.deletedSuccessfully) {
    return true;
  }
  throw Error(`Error deleting user. Message:${res.message ?? 'n/a'}`);
}

export async function getReportCategoryTypesAndReportTypes(): Promise<Array<acxessReportCategoryType>> {
  const res = await fetchWithIntercept(`${acxessApiHost}/report/report-category-types-and-report-types`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
    });
  if (res.success) {
    return res.reportCategoryTypes;
  }
  throw Error(`Error retrieving report category types and report types.  Message:${res.message ?? 'n/a'}`);
}

export async function searchReports(page: number, resultsPerPage: number, reportType?: number, reportStatus?: number, reportCategoryType?: number): Promise<acxessPagedResponse<Array<acxessReport>>> {
  const res = await fetchWithIntercept(`${acxessApiHost}/report/search-reports`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify({
        Page: page,
        ResultsPerPage: resultsPerPage,
        ReportType: reportType,
        ReportStatus: reportStatus,
        ReportCategoryType: reportCategoryType,
      }),
    });
  if (res.success) {
    return {
      message: res.message,
      page: res.page,
      totalPages: res.totalPages,
      success: res.success,
      totalItems: res.totalReports,
      payload: res.reportResults,
    };
  }
  throw Error('Failed to get reports.');
}

export async function queueDetailReport(startDate: string, endDate: string, reportType: number, lineIds: Array<string>): Promise<boolean> {
  const res = await fetchWithIntercept(`${acxessApiHost}/report/queue-detail-report`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify({
        StartDate: startDate,
        EndDate: endDate,
        ReportType: reportType,
        LineIds: lineIds,
      }),
    });
  if (res.success) {
    return true;
  }
  throw Error(`Error queueing report. Message:${res.message ?? 'n/a'}`);
}

export async function queueConversationReport(startDate: string, endDate: string, reportType: number, lineIds: Array<string>, contactMobileNumber: string): Promise<boolean> {
  const res = await fetchWithIntercept(`${acxessApiHost}/report/queue-conversation-report`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify({
        StartDate: startDate,
        EndDate: endDate,
        ReportType: reportType,
        LineIds: lineIds,
        ContactMobileNumber: contactMobileNumber,
      }),
    });
  if (res.success) {
    return true;
  }
  throw Error(`Error queueing report. Message:${res.message ?? 'n/a'}`);
}

export async function queueVisualReport(startDate: string, endDate: string, reportType: number, lineIds: Array<string>): Promise<boolean> {
  const res = await fetchWithIntercept(`${acxessApiHost}/report/queue-visual-report`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify({
        StartDate: startDate,
        EndDate: endDate,
        ReportType: reportType,
        LineIds: lineIds,
      }),
    });
  if (res.success) {
    return true;
  }
  throw Error(`Error queueing report. Message:${res.message ?? 'n/a'}`);
}

export async function queueResponderUsageCountReport(startDate: string, endDate: string, reportType: number, lineIds: Array<string>): Promise<boolean> {
  const res = await fetchWithIntercept(`${acxessApiHost}/report/queue-responder-usage-report`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify({
        StartDate: startDate,
        EndDate: endDate,
        ReportType: reportType,
        LineIds: lineIds,
      }),
    });
  if (res.success) {
    return true;
  }
  throw Error(`Error queueing report. Message:${res.message ?? 'n/a'}`);
}

export async function queueBlastSummaryReport(reportType: number, blastId: string): Promise<boolean> {
  const res = await fetchWithIntercept(`${acxessApiHost}/report/queue-blast-summary-report`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify({
        ReportType: reportType,
        CampaignId: blastId,
      }),
    });
  if (res.success) {
    return true;
  }
  throw Error(`Error queueing report. Message:${res.message ?? 'n/a'}`);
}

export async function queueBlastDetailReport(reportType: number, blastId: string): Promise<boolean> {
  const res = await fetchWithIntercept(`${acxessApiHost}/report/queue-blast-detail-report`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify({
        ReportType: reportType,
        CampaignId: blastId,
      }),
    });
  if (res.success) {
    return true;
  }
  throw Error(`Error queueing report. Message:${res.message ?? 'n/a'}`);
}

export async function queueDetailedLinkClickReport(startDate: string, endDate: string, reportType: number): Promise<boolean> {
  const res = await fetchWithIntercept(`${acxessApiHost}/report/queue-detailed-link-click-report`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify({
        StartDate: startDate,
        EndDate: endDate,
        ReportType: reportType,
      }),
    });
  if (res.success) {
    return true;
  }
  throw Error(`Error queueing report. Message:${res.message ?? 'n/a'}`);
}

export async function queueSummaryLinkClickReport(startDate: string, endDate: string, reportType: number): Promise<boolean> {
  const res = await fetchWithIntercept(`${acxessApiHost}/report/queue-summary-link-click-report`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify({
        StartDate: startDate,
        EndDate: endDate,
        ReportType: reportType,
      }),
    });
  if (res.success) {
    return true;
  }
  throw Error(`Error queueing report. Message:${res.message ?? 'n/a'}`);
}

export async function queueTranslationUsageReport(startDate: string, endDate: string, reportType: number): Promise<boolean> {
  const res = await fetchWithIntercept(`${acxessApiHost}/report/queue-translation-usage-report`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify({
        StartDate: startDate,
        EndDate: endDate,
        ReportType: reportType,
      }),
    });
  if (res.success) {
    return true;
  }
  throw Error(`Error queueing report. Message:${res.message ?? 'n/a'}`);
}

export async function retrieveReportFile(request: { reportId: string, downloadableFileName: string }): Promise<any> {
  const res = await fetchWithIntercept(`${acxessApiHost}/report/download-report-file`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify(request),
    });
  if (res.success) {
    return res.file;
  }
  throw Error(`Error downloading file. Message:${res.message ?? 'n/a'}`);
}

export async function checkMessageUsage(): Promise<acxessGraph> {
  const res = await fetchWithIntercept(`${acxessApiHost}/billing/check-message-usage`,
    {
      method: 'GET',
      headers: buildAcxessAuthenticatedHeader(),
    });
  if (res.success) {
    return res.graph;
  }
  throw Error('Error getting campaign results');
}

export async function getReportData(reportId: string): Promise<acxessReportData> {
  const file = await retrieveReportFile({ reportId, downloadableFileName: `${reportId}.json` });
  return JSON.parse(Buffer.from(file.fileContents, 'base64').toString());
}

export async function generateBigNumberStats(reportPeriod: reportPeriodType): Promise<dashboardBigNumbers> {
  let cacheData = false;

  // only going to cache the default reportPeriod
  // we don't have a good way of caching each one on the FE yet
  if (reportPeriod === reportPeriodType.Rolling7Day) {
    cacheData = true;
  }
  const res = await fetchWithIntercept(generateBigNumberStatsUrl,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify({
        ReportPeriod: reportPeriod,
      }),
    }, cacheData);
  if (res.success) {
    return res;
  }
  throw Error('Error generating big number stats');
}

export async function searchContactImports(page: number, resultsPerPage: number): Promise<acxessPagedResponse<Array<acxessContactImportContent>>> {
  const res = await fetchWithIntercept(`${acxessApiHost}/contact/search-contact-imports`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify({
        Page: page,
        ResultsPerPage: resultsPerPage,
      }),
    });
  if (res.success) {
    return {
      page: res.page,
      totalPages: res.totalPages,
      totalItems: res.totalContactImports,
      success: res.success,
      message: res.message,
      payload: res.contactImportResults,
    };
  }
  throw Error('Failed to get contact imports.');
}

export async function retrieveContactImportErrorFile(request: { contactImportId: string, downloadableFileName: string }): Promise<any> {
  const res = await fetchWithIntercept(`${acxessApiHost}/contact/download-contact-import-error-file`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify(request),
    });
  if (res.success) {
    return res.file;
  }
  throw Error(`Error downloading file. Message:${res.message ?? 'n/a'}`);
}

export async function getLineIntegrationAppOptions(lineId: string): Promise<acxessLineIntegrationPlatformAppOptions> {
  const result = await fetchWithIntercept(`${acxessApiHost}/line/app-integration-options/${lineId}`, {
    method: 'GET',
    headers: buildAcxessAuthenticatedHeader(),
  });
  if (result.success) {
    return result.lineIntegrationPlatformAppOptions as acxessLineIntegrationPlatformAppOptions;
  }
  throw Error('failed to get line integration app options');
}

export async function getUserSignature(): Promise<acxessUserSignature> {
  const res = await fetchWithIntercept(`${acxessApiHost}/user/get-user-signature`, {
    method: 'GET',
    headers: buildAcxessAuthenticatedHeader(),
  });
  if (res.success) {
    return res;
  }
  throw Error(`Error getting user signature. Message:${res.message}`);
}

export async function saveUserSignature(signature: string, enabled: boolean): Promise<void> {
  const res = await fetchWithIntercept(`${acxessApiHost}/user/save-user-signature`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify({
        Signature: signature,
        IsSignatureEnabled: enabled,
      }),
    });
  if (res.success) {
    globalStore.dispatch('auth/userSignature', {
      userSignature: {
        signature,
        isSignatureEnabled: enabled,
      },
    });
    return;
  }
  throw Error(`Error saving user signature. Message:${res.message ?? 'n/a'}`);
}

export async function softDeleteMessage(messageId: string): Promise<boolean> {
  const res = await fetchWithIntercept(`${acxessApiHost}/messages/soft-delete-message/${messageId}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
    });
  return res.success;
}

export async function softUndeleteMessage(messageId: string): Promise<boolean> {
  const res = await fetchWithIntercept(`${acxessApiHost}/messages/soft-undelete-message/${messageId}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
    });
  return res.success;
}

export async function updateCustomerLineName(lineId: string, lineName: string): Promise<void> {
  const res = await fetchWithIntercept(`${acxessApiHost}/line/customer-line-name`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify({
        lineId,
        name: lineName.trim(),
      }),
    });
  if (res.success) {
    return;
  }
  throw Error(`Error saving customer line name. Message:${res.message ?? 'n/a'}`);
}

export async function initializeTwoFactorSetup(twoFactorMethodType: number): Promise<initializeTwoFactorResponse> {
  const res = await fetchWithIntercept(`${acxessApiHost}/user/initialize-two-factor-setup`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify({
        twoFactorMethodType,
      }),
    });
  if (res.success) {
    return res;
  }
  throw Error('Error initializing two factor setup.');
}

export async function activateTwoFactorMethod(twoFactorCode: string, id: string, rememberDevice: boolean): Promise<boolean> {
  const res = await fetchWithIntercept(`${acxessApiHost}/user/activate-two-factor-method`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify({
        twoFactorCode,
        id,
        rememberDevice,
      }),
    });
  return res.setupSuccessfully;
}

export async function getUserTwoFactorMethods(): Promise<getUserTwoFactorResponse> {
  const res = await fetchWithIntercept(`${acxessApiHost}/user/user-two-factor-methods`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
    });
  return res;
}

export async function deactivateTwoFactorMethod(id: string): Promise<boolean> {
  const res = await fetchWithIntercept(`${acxessApiHost}/user/deactivate-two-factor-method`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify({
        id,
      }),
    });
  return res.deactivatedSuccessfully;
}

export async function saveAccountCustomContactFields(accountCustomContactFields: Array<saveAccountCustomContactFieldContent>): Promise<Array<accountCustomContactField>> {
  const res = await fetchWithIntercept(`${acxessApiHost}/contact/save-account-custom-contact-fields`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify({
        accountCustomContactFields,
      }),
    });
  if (res.success) {
    return res.accountCustomContactFields;
  }
  throw Error('Error saving account custom contact fields.');
}

export async function getAccountCustomContactFields(): Promise<Array<accountCustomContactField>> {
  const res = await fetchWithIntercept(`${acxessApiHost}/contact/account-custom-contact-fields`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
    });
  if (res.success) {
    return res.accountCustomContactFields;
  }
  throw Error('Error retrieving account custom contact fields.');
}

export async function queueContactsLineIntelligence(): Promise<boolean> {
  const res = await fetchWithIntercept(`${acxessApiHost}/contact/queue-contacts-line-intelligence`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
    });
  return res.success;
}
