import ExceptionInvalidToken from '@/models/exceptions/exceptionInvalidToken';
import AcxessException from '@/models/exceptions/acxessException';

export enum logType {
  Info = 1,
  Warning,
  Error,
}

function writeLog(type:logType, message:string) {
  switch (type) {
    case logType.Error:
      console.error(message);
      break;
    case logType.Warning:
      console.warn(message);
      break;
    default:
      console.log(message);
  }
}

export function logMessage(type:logType, message:string):void {
  if (message.trim().length < 1) return;
  writeLog(type, message);
}

export function logAcxessException(type:logType, exception:any):void {
  // ignore ExceptionInvalidToken errors... if we need to add more exceptions, this will need a refactor
  if (exception instanceof ExceptionInvalidToken) return;
  if (exception instanceof AcxessException) {
    logMessage(type, exception.message);
    return;
  }
  if (exception instanceof String) {
    logMessage(type, exception as string);
    return;
  }
  writeLog(type, exception);
}
