import jwt_decode from 'jwt-decode';
import { LocalStorageKeys } from '@/keys';
import { acxessRole, acxessUserSignature } from '@/models/acxess';
import { EventBus, Channels } from '@/eventbus';
import { logAcxessException, logType } from '@/services/logger';
import { isNullOrEmpty } from '@/utils';
import { customDomain } from '@/models/short-links';

export const acxessAuth = {
  namespaced: true,
  state: {
    token: '',
    status: '',
    roles: [],
    session: {},
    permissions: {},
    agentId: '',
    accountTimezone: '',
    userSignature: {},
    accountCustomDomain: {},
  },
  mutations: {
    loginError(state:any):void {
      state.status = 'error';
    },
    logout(state:any):void {
      state.status = '';
      state.token = '';
      state.session = {};
      state.permissions = {};
      state.agentId = '';
      state.accountTimezone = '';
      state.userSignature = {};
      state.accountCustomDomain = {};
    },
    roles(state:any, newRoles:Array<acxessRole>):void {
      state.roles = newRoles;
    },
    token(state: any, newToken: string): void {
      try {
        state.token = newToken;
        if (newToken.length > 0) {
          state.session = jwt_decode(newToken);
          state.permissions = JSON.parse(state.session.Permissions);
        }
      } catch (e) {
        logAcxessException(logType.Error, e);
        localStorage.setItem(LocalStorageKeys.userToken, '');
      }
    },
    agentId(state:any, newAgentId:string):void {
      if (newAgentId != null && newAgentId.length > 0) {
        state.agentId = newAgentId;
      }
    },
    accountTimezone(state: any, accountTimezone: string): void {
      if (accountTimezone != null && accountTimezone.length > 0) {
        state.accountTimezone = accountTimezone;
      }
    },
    userSignature(state: any, userSignature: acxessUserSignature):void {
      if (userSignature != null) {
        state.userSignature = userSignature;
      }
    },
    accountCustomDomain(state: any, accountCustomDomain: customDomain): void {
      if (accountCustomDomain != null) {
        state.accountCustomDomain = accountCustomDomain;
      }
    },
  },
  getters: {
    isLoggedIn: (state:any) => state.token != null && state.token.trim().length > 0,
    authStatus: (state:any) => state.status,
    token: (state:any) => state.token,
    roles: (state:any) => state.roles,
    session: (state:any) => state.session,
    permissions: (state:any) => state.permissions,
    agentId: (state: any) => state.agentId,
    accountTimezone: (state: any) => {
      if (isNullOrEmpty(state.accountTimezone)) {
        return 'UTC';
      }
      return state.accountTimezone;
    },
    userSignature: (state: any) => state.userSignature,
    accountCustomDomain: (state: any) => state.accountCustomDomain,
  },
  actions: {
    startup: (context: any) => {
      context.commit('token', localStorage.getItem(LocalStorageKeys.userToken) || '');
      context.commit('agentId', localStorage.getItem(LocalStorageKeys.userAgentId) || '');
      context.commit('accountTimezone', localStorage.getItem(LocalStorageKeys.accountTimezone));
      const rawUserSig = localStorage.getItem(LocalStorageKeys.userSignature);
      if (rawUserSig != null) {
        context.commit('userSignature', JSON.parse(rawUserSig));
      }
      const rawCustomDomain = localStorage.getItem(LocalStorageKeys.accountCustomDomain);
      if (rawCustomDomain != null) {
        context.commit('accountCustomDomain', JSON.parse(rawCustomDomain));
      }
    },
    login: (context: any, params: { token: string }) => {
      localStorage.setItem(LocalStorageKeys.userToken, params.token);
      context.commit('token', params.token);
      EventBus.$emit(Channels.login);
    },
    logout: (context:any) => {
      localStorage.removeItem(LocalStorageKeys.userToken);
      localStorage.removeItem(LocalStorageKeys.userAgentId);
      localStorage.removeItem(LocalStorageKeys.userSignature);
      localStorage.removeItem(LocalStorageKeys.accountCustomDomain);
      context.dispatch('filters/clearBlastFilters', null, { root: true });
      context.commit('logout');
      EventBus.$emit(Channels.logout);
    },
    roles: (context:any, params: { roles:Array<acxessRole> }) => {
      context.commit('roles', params.roles);
    },
    agentId: (context:any, params: { agentId:string }) => {
      localStorage.setItem(LocalStorageKeys.userAgentId, params.agentId);
      context.commit('agentId', params.agentId);
    },
    accountTimezone: (context: any, params: { accountTimezone: string }) => {
      localStorage.setItem(LocalStorageKeys.accountTimezone, params.accountTimezone);
      context.commit('accountTimezone', params.accountTimezone);
    },
    userSignature: (context: any, params: { userSignature: acxessUserSignature }) => {
      localStorage.setItem(LocalStorageKeys.userSignature, JSON.stringify(params.userSignature));
      context.commit('userSignature', params.userSignature);
    },
    accountCustomDomain: (context: any, params: { accountCustomDomain: customDomain }) => {
      localStorage.setItem(LocalStorageKeys.accountCustomDomain, JSON.stringify(params.accountCustomDomain));
      context.commit('accountCustomDomain', params.accountCustomDomain);
    },
  },
};

export function isTokenExpired(token: string): boolean {
  const decoded: any = jwt_decode(token);
  const tokenExpiration = decoded.exp * 1000;
  return new Date().getTime() > tokenExpiration;
}
