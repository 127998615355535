// turning off no-param-reassign b/c we are setting Vue plugin stuff at the global
/* eslint no-param-reassign: 0 */

import dayjs from 'dayjs';
import RelativeTime from 'dayjs/plugin/relativeTime';
import LocalizedTime from 'dayjs/plugin/localizedFormat';
import UpdateLocale from 'dayjs/plugin/updateLocale';
import UTC from 'dayjs/plugin/utc';
import { t, setLanguage } from './translate';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';

dayjs.extend(RelativeTime);
dayjs.extend(LocalizedTime);
dayjs.extend(UpdateLocale);
dayjs.extend(UTC);

const updateLanguage = (lang:string):void => {
  setLanguage(lang);
  dayjs.locale(lang);
};

export default {
  install(Vue:any, options:any):void {
    Vue.directive('bw-pg-t', {
      bind(el:Element):void {
        el.innerHTML = t(el.innerHTML);
      },
    });

    Vue.directive('bw-pg-fromnow', {
      bind(el:Element, binding:any):void {
        el.innerHTML = dayjs(binding.value).fromNow();
      },
    });

    Vue.directive('bw-pg-smalldt', {
      bind(el:Element, binding:any):void {
        el.innerHTML = dayjs(binding.value).format('lll');
      },
    });

    Vue.directive('bw-pg-longdt', {
      bind(el:Element, binding:any):void {
        el.innerHTML = dayjs(binding.value).format('LLLL');
      },
    });

    Vue.directive('bw-pg-format', {
      bind(el:Element, binding:any):void {
        el.innerHTML = dayjs(el.innerHTML).format(binding.arg ?? 'lll');
      },
    });

    Vue.directive('bw-pg-lang', {
      bind(el:Element, binding:any):void {
        updateLanguage(binding.value);
      },
      update(el:Element, binding:any):void {
        updateLanguage(binding.value);
      },
      componentUpdated(el:Element, binding:any):void {
        updateLanguage(binding.value);
      },
    });

    Vue.prototype.$bwDateFormat = (date:Date, format:string) => dayjs(date).format(format);
    Vue.prototype.$bwDateFromNow = (date:Date):string => dayjs(date).fromNow();
    Vue.prototype.$bwUpdateLanguage = (lang:string) => updateLanguage(lang);
    Vue.prototype.$bwDateToUTC = (date:Date) => dayjs.utc(date).format();
    Vue.prototype.$bwDaysBetween = (date2:Date, date1:Date):number => (dayjs(date2).endOf('day').diff(dayjs(date1).endOf('day'), 'day'));
    Vue.prototype.$bwDateGetTime = (date: Date) => dayjs.utc(date).toDate().getTime();
    Vue.prototype.$bwSmallDateFormat = (date: Date) => dayjs(date).format('lll');
    Vue.prototype.$bwTranslate = (key: string) => t(key);
    updateLanguage(options?.locale ?? 'en');
  },
};
