import toArray from 'lodash.toarray';
import parsePhoneNumber, { isValidPhoneNumber } from 'libphonenumber-js';
import { StyleVersion } from '@/models/style-version';

const e164Regex = new RegExp(/^\+\d{3,15}$/);
const shortCodeRegex = new RegExp(/^[0-9]{4,6}/);
export const dirtyChars = new RegExp(/[\s.()-]/g);
const urlPattern = new RegExp(/\b(?:https?|ftp):\/\/[a-z0-9-+&@#/%?=~_|!:,.;]*[a-z0-9-+&@#/%=~_|]/gim);
const pseudoUrlPattern = new RegExp(/(^|[^/])(www\.[\S]+(\b|$))/gim);
const emailAddressPattern = new RegExp(/\w+@[a-zA-Z0-9_]+?(?:\.[a-zA-Z]{2,6})+/gim);
const emptyStringPattern = new RegExp(/^\s*$/gi);
const onlyWordsPattern = new RegExp(/[^\w]+/g);
const imageFileTypePattern = new RegExp(/(^image)(\/)[a-zA-Z0-9]+/);
const videoFileTypePattern = new RegExp(/(^video)(\/)[a-zA-Z0-9]+/);
const authViaUrlPattern = new RegExp(/(https?:\/\/lock.authvia.com)(\/[a-zA-Z0-9]+)?/);

let blinkInterval: any = null;
const supportedFileExtensions = ['.json', '.doc', '.ogx', '.pdf', '.rtf', '.vcf', '.pkpass', '.docx', '.xls', '.xlsx', '.ppt', '.pptx', '.vsd', '.tar', '.zip', '.3gp', '.3g2', '.ac3', '.amr', '.au', '.l24', '.mp4', '.mp3', '.ogg', '.ra', '.wave', '.wav', '.weba', '.m4a', '.bmp', '.dib', '.heic', '.jpg', '.jpeg', '.pjpg', '.png', '.gif', '.svg', '.tiff', '.ico', '.webp', '.ics', '.css', '.csv', '.dir', '.html', '.txt', '.rtx', '.wml', '.vcf', '.3gp', '.3g2', '.3gpp', '.h261', '.h263', '.h264', '.mp4', '.mpeg', '.ogv', '.qt', '.webm', '.flv', '.m4v', '.wmv', '.avi'];
const validImageTypes = ['.png', '.jpg', '.jpeg', '.gif', '.bmp', '.ico', '.jfif'];
const videoFileExtensions = ['.3gp', '.3g2', '.mp4', '.m4v', '.mpeg', '.webm', '.flv', '.wmv', '.avi', '.qt', '.ogv', '.h261', '.h263', '.h264'];

export function isNullOrEmpty(target: Array<any> | string | null | undefined): boolean {
  if (target == null) return true;
  if (typeof target === 'string') {
    const stringTarget = target as string;
    if (stringTarget.trim().length < 1) return true;
    const emptyMatch = stringTarget.trim().match(emptyStringPattern);
    return emptyMatch != null && emptyMatch.length > 0;
  }
  const arrayTarget = target as Array<any>;
  return arrayTarget.length < 1;
}

export function cleanPhoneNo(phNo:string):string {
  if (phNo == null) return '';
  let result = '';
  if (!(phNo.trim().length > 3 && phNo.trim().length < 7)) {
    if (!phNo.startsWith('+')) result = '+1';
  }
  result += phNo.trim().replaceAll(dirtyChars, '');
  return result;
}

export function formatPhoneNumber(rawE164: string): string {
  let convertedNumber = rawE164;
  if (isNullOrEmpty(rawE164)) return convertedNumber;
  // short code
  if (rawE164.trim().length > 3 && rawE164.trim().length < 7) return rawE164.trim();
  const parsedNumber = parsePhoneNumber(rawE164);
  if (parsedNumber != null) {
    if (parsedNumber.country === 'US') {
      convertedNumber = `+1 ${parsedNumber.formatNational()}`;
    } else {
      convertedNumber = parsedNumber.formatInternational();
    }
  }
  return convertedNumber.trim();
}

export function validatePhoneNumber(phno: string): boolean {
  if (phno != null && phno.trim().length > 3 && phno.trim().length < 7) {
    const matchesShort = cleanPhoneNo(phno).match(shortCodeRegex);
    return matchesShort != null && matchesShort.length > 0;
  }
  if (phno.startsWith('+')) {
    return isValidPhoneNumber(phno);
  }
  // default US country code for now if one isn't provided
  return isValidPhoneNumber(`+1${phno}`);
}

export function localTimeFromUTC(date:Date):Date {
  const timeOffsetInMS = new Date().getTimezoneOffset() * 60000;
  return new Date(new Date(date).getTime() - timeOffsetInMS);
}

export function utcToLocalTime(date: Date): Date {
  const timeOffsetInMS = new Date().getTimezoneOffset() * 60000;
  return new Date(new Date(date).getTime() + timeOffsetInMS);
}

export function linkify(source:string):string {
  return source
    .replace(urlPattern, '<a href="$&" target="_blank">$&</a>')
    .replace(pseudoUrlPattern, '$1<a href="http://$2" target="_blank">$2</a>')
    .replace(emailAddressPattern, '<a href="mailto:$&">$&</a>');
}

export function scrubAuthViaUrl(source: string): string {
  return source.replace(authViaUrlPattern, '(payment link redacted)');
}

export function validateEmail(email:string, isOptional = false):boolean {
  if (isOptional && (email == null || email.trim().length === 0)) return true;
  const matches = email.trim().match(emailAddressPattern);
  return matches != null && matches.length > 0;
}

export function validateString(target:string | null | undefined, isOptional = false):boolean {
  if (isOptional && (target == null || target.trim().length === 0)) return true;
  return !isNullOrEmpty(target);
}

export function validateArray(target:Array<any> | undefined | null, isOptional = false):boolean {
  if (isOptional && (target == null || target.length === 0)) return true;
  return !isNullOrEmpty(target);
}

export function blinkTitle(newTitle: string): void {
  const isProduction = process.env.VUE_APP_HOSTING_ENV === 'PROD';
  let oldTitle = '';
  if (isProduction) {
    oldTitle = document.title.substring(0, document.title.indexOf('|') - 1);
  } else {
    oldTitle = document.title.substring(document.title.indexOf(' ') + 1, document.title.indexOf('|') - 1);
  }
  if (blinkInterval != null) {
    clearInterval(blinkInterval);
  }
  blinkInterval = setInterval(() => {
    if (document.title.includes(oldTitle)) {
      document.title = document.title.replace(oldTitle, newTitle);
    } else {
      document.title = document.title.replace(newTitle, oldTitle);
    }
  }, 2000);
}

export function stopBlinkingTitle(newTitle: string): void {
  if (blinkInterval != null) {
    clearInterval(blinkInterval);
  }
  document.title = document.title.replace(newTitle, 'Conversations');
}

export function getComputedCssVarColor(cssVarName:string):string {
  return getComputedStyle(document.documentElement).getPropertyValue(cssVarName);
}

export function getUtcOffset(date: Date, timezone: string): number {
  const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
  const timezoneDate = new Date(date.toLocaleString('en-US', { timeZone: timezone }));
  const offset = timezoneDate.getTime() - utcDate.getTime(); // Account timezone offset from UTC in milliseconds
  return offset;
}

export function getLocalOffset(date: Date, timezone: string): number {
  const localDate = new Date(date.toLocaleString('en-US'));
  const timezoneDate = new Date(date.toLocaleString('en-US', { timeZone: timezone }));
  const offset = timezoneDate.getTime() - localDate.getTime(); // Account timezone offset from local in milliseconds
  return offset;
}

/* Javascript assumes every date is in the local timezone. If we select 9:00 on the DateTimePicker, it thinks it's 9:00 */
export function convertFromTimezone(date: Date, timezone: string) {
  const offset = getLocalOffset(date, timezone);
  const convertedDate = new Date(date.getTime() - offset);
  return convertedDate;
}

/* This function will return an alteration of the input date by adding the UTC offset of the input timezone. */
export function convertToTimezone(date: Date, timezone: string, fromUtc = true, display = true): Date {
  let convertedDate;
  if (typeof date === 'string') {
    const newDate = new Date(`${date}Z`);
    convertedDate = new Date(newDate.toLocaleString('en-US', { timeZone: timezone }));
  } else {
    const offset = fromUtc ? getUtcOffset(date, timezone) : getLocalOffset(date, timezone);
    convertedDate = new Date(date.getTime() + offset);
  }
  /* Javascript always assumes dates are in the local user's timezone. This method is partially used for timezone display
     (display = true) and partially used for representative dates (display = false). */
  if (display === false) convertedDate = convertFromTimezone(convertedDate, timezone);
  return convertedDate;
}

/* To get an ISO-formatted datestring without Javascript automatically converting to UTC. */
export function toISOString(date: Date, timezone: string, fromUTC = true): string {
  const adjustedDate = convertToTimezone(date, timezone, fromUTC);
  const isoString = adjustedDate.toISOString();
  return isoString;
}

/* This function takes two locale time strings and returns whether the first is greater than the second.
   Direct string comparison between the two will return the opposite of the expected result. */
export function isGreaterLocaleTime(firstTime: string, secondTime: string): boolean {
  const firstHour = parseInt(firstTime.split(':')[0], 10);
  const secondHour = parseInt(secondTime.split(':')[0], 10);
  if (firstHour === secondHour) {
    const firstMinute = parseInt(firstTime.split(':')[1], 10);
    const secondMinute = parseInt(secondTime.split(':')[1], 10);
    if (firstMinute === secondMinute) {
      const firstSecond = parseInt(firstTime.split(':')[2], 10);
      const secondSecond = parseInt(secondTime.split(':')[2], 10);
      return firstSecond > secondSecond;
    }
    return firstMinute > secondMinute;
  }
  return firstHour > secondHour;
}

export function loginRedirectUrl(documentPath: string, documentSearch: string): string {
  return `/login?rurl=${encodeURIComponent(documentPath)}${encodeURIComponent(documentSearch)}`;
}

export function isVideoFileType(file: File | string): boolean {
  if (typeof file === 'string') {
    const videoType = videoFileExtensions.find((x) => file.toLocaleLowerCase().endsWith(x));

    return videoType != null;
  }

  return videoFileTypePattern.test(file.type);
}

export function isImageFileType(file: File): boolean {
  if (!file?.type) return false;

  return imageFileTypePattern.test(file.type);
}

export function isValidImageType(attachment: string | File): boolean {
  if (typeof attachment === 'string') {
    const imageType = validImageTypes.find((x) => attachment.toLowerCase().endsWith(x));

    return imageType != null;
  }

  return isImageFileType(attachment);
}

export function fileNameFriendlyString(rawString:string):string {
  return rawString.trim().toLowerCase().replaceAll(onlyWordsPattern, '');
}

export function isSupportedFile(fileName: string): boolean {
  const fileExt = supportedFileExtensions.find((x) => fileName.toLowerCase().endsWith(x));

  return fileExt != null;
}

/* An emoji-friendly string truncation method. */
export function truncate(string: string, length: number): string {
  let array = toArray(string); // Uses lodash's toArray method to properly handle emojis
  const currentLength = array.length;
  if (currentLength > length) {
    array = array.splice(0, length);
    const ellipsis = '...';
    const truncatedString = array.join('') + ellipsis;
    return truncatedString;
  }
  return string;
}

export function appendSignatureIfValid(message: string, signature: string | undefined): string {
  return isNullOrEmpty(signature) ? message : `${message ?? ''}\r\n\r\n${signature}`;
}

export function messageContainsUnicode(value: string): boolean {
  const utf8Count = Buffer.byteLength(value, 'utf-8');

  return utf8Count !== value.length;
}

export function getMessageByteCount(value: string, signature: string | undefined = undefined): number {
  let count = 0;
  const string = appendSignatureIfValid(value, signature);
  if (!isNullOrEmpty(string)) {
    if (messageContainsUnicode(string)) {
      count = Buffer.byteLength(string, 'utf16le') / 2;
    } else {
      count = string.length;
    }
  }

  return count;
}

export function getSmsCount(value: string): number {
  if (value?.length == null || value?.length <= 0) return 0;

  let count = 1;
  if (messageContainsUnicode(value)) {
    if (value.length > 70) {
      count = Math.ceil(value.length / 67);
    }
  } else if (value.length > 160) {
    count = Math.ceil(value.length / 153);
  }
  return count;
}

export function getStyleVersion(): StyleVersion {
  const styleVersion = parseInt(process.env.VUE_APP_STYLE_VERSION ?? '1', 10);

  return styleVersion as StyleVersion;
}
