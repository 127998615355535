import Polyglot from 'node-polyglot';
import { locales } from './i18n';

const pg:Polyglot = new Polyglot();

export function setLanguage(newLang:string):void {
  pg.extend(locales[newLang]);
}

export function t(source:string):string {
  return pg.t(source);
}
