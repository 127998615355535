import { acxessContact } from '@/models/acxess';

enum MessageStatus {
    Sending = 1,
    Delivered,
    Failed,
    OptedOut,
    PartialFailure,
    PartialOptOut,
}

interface Message {
    id?: string,
    body?: string,
    status: MessageStatus,
    isDeleted?: boolean,
    sent?: Date,
    delivered?: Date,
    received?: Date,
    read?: Date,
    author?: acxessContact,
    sentiment?: number,
    inbound?: boolean,
    type?: string,
    attachmentUrls?: Array<string>,
    productId?: number,
    hasTranslation?: boolean,
    translatedText?: string
}

export default Message;
export { MessageStatus };
