
import Vue from 'vue';
import { RouteRecordPublic } from 'vue-router';
import { EventBus, Channels } from '@/eventbus';
import globalStore from '@/store';
import Header from '@/components/Header.vue';
import SupportTooltip from '@/components/SupportTooltip.vue';
import { allowedRailRoutes } from '@/router';
import { getRoles } from '@/services/acxess';
import { authenticateMerchant } from '@/services/platform-authVia';
import { stopMessageHub } from '@/services/signalr';
import { isNullOrEmpty } from '@/utils';
import FeatureToggle from './components/FeatureToggle.vue';
import { acxessFeatureAccess, acxessProductIds } from './models/acxess';
import { AuthViaAuthenticationScope } from './models/platform-authVia';

export default Vue.extend({
  name: 'app',
  components: { Header, SupportTooltip, FeatureToggle },
  data() {
    return {
      gstore: globalStore.state,
      drawer: true,
      showSnackbar: false,
      snackMessage: '',
      snackTimeout: 5000,
      notificationsEnabled: false,
      PaymentsPermissionsSet: {
        productId: acxessProductIds.Payments,
        accessLevels: [
          acxessFeatureAccess.View,
          acxessFeatureAccess.ViewAndChange,
          acxessFeatureAccess.ViewAndExecute,
          acxessFeatureAccess.ViewAndChangeAndExecute,
        ],
      },
    };
  },
  async created() {
    EventBus.$on(Channels.snackbarMessage, this.handleSnackbarMessage);
    // seed initial data
    globalStore.dispatch('auth/roles', { roles: await getRoles() });
  },
  async beforeDestroy() {
    EventBus.$off(Channels.snackbarMessage, this.handleSnackbarMessage);
    await stopMessageHub();
  },
  computed: {
    contentContainClass(): any {
      return {
        'content-contain': true,
        'rounded-tl-xl': true,
        'content-contain-with-nav': this.$route.meta?.showNav,
      };
    },
    userRoutes(): Array<RouteRecordPublic> {
      return allowedRailRoutes();
    },
  },
  methods: {
    handleSnackbarMessage(message: string): void {
      this.snackMessage = message;
      this.showSnackbar = true;
    },
    showRailNotification(route: any): boolean {
      const unreadMessagesExist = globalStore.getters['conversations/unreadGroupChatIds'].length > 0;
      const routeTitle = route != null && route.meta != null ? route.meta.pageTitle : '';
      const currentTitle = this.$route != null && this.$route.meta != null ? this.$route.meta.pageTitle : '';
      if (this.notificationsEnabled === false) {
        this.notificationsEnabled = this.$store.getters['app/appNotificationsEnabled'];
      }
      /* If...
        * 1) Notifications are enabled
        * 2) At least one unread message exists
        * 3) The input route leads to the Conversations page
        * 4) We're not on the Conversations page
        * Then we want to show the rail notification
        */

      if (this.notificationsEnabled && unreadMessagesExist && routeTitle.includes('Conversations') && !currentTitle.includes('Conversations')) {
        return true;
      }
      return false;
    },
    async routeToAuthVia(): Promise<void> {
      let authViaPortalUrl = 'https://beta-app.authvia.net';
      const token = await authenticateMerchant(AuthViaAuthenticationScope.PortalLogin);
      if (!isNullOrEmpty(token)) {
        authViaPortalUrl = `${authViaPortalUrl}/?t=${token}`;
      }
      window.open(authViaPortalUrl);
    },
  },
});
