import { buildPlatformAuthenticatedHeader, fetchWithIntercept } from '.';

const platformApiHost: string = process.env.VUE_APP_ACXESS_API_URL!;

export async function authenticateWithDfoToken(token:string, brandId:number, niceUserId:number, apiHost:string): Promise<string> {
  const res = await fetchWithIntercept(`${platformApiHost}/nicedfo/authenticate`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      apiHost,
      brandId,
      niceUserId,
      token,
    }),
  });

  return res.token;
}

export async function createChannel(brandId:string, niceUserId:string, customerLineId:string): Promise<boolean> {
  const res = await fetchWithIntercept(`${platformApiHost}/nicedfo/create-channel`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...buildPlatformAuthenticatedHeader(),
    },
    body: JSON.stringify({
      customerLineId,
      brandId,
      niceUserId,
    }),
  });

  return res.success;
}

export async function deleteChannel(brandId:string, channelId: string): Promise<boolean> {
  const res = await fetchWithIntercept(`${platformApiHost}/nicedfo/delete-channel`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      ...buildPlatformAuthenticatedHeader(),
    },
    body: JSON.stringify({
      channelId,
      brandId,
    }),
  });

  return res.success;
}
