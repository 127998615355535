
import Vue from 'vue';
import { RouteRecordPublic } from 'vue-router';
import { allowedHeaderRoutes } from '@/router';
import { clearServiceCache } from '@/services';
import { logout } from '@/services/acxess';
import Beamer from '@/components/Beamer.vue';
import NavigationMenu from '@/components/NavigationMenu.vue';
import ProfileMenu from '@/components/ProfileMenu.vue';
import { getStyleVersion } from '@/utils';

export default Vue.extend({
  components: { Beamer, NavigationMenu, ProfileMenu },
  name: 'Header',
  data() {
    return {
      headerRoutes: [] as any,
      openNavMenu: false,
    };
  },
  mounted() {
    // build up menu object for header
    const allowedRoutes = allowedHeaderRoutes();
    let rid = 1;
    for (let i = 0; i < allowedRoutes.length; i++) {
      if (allowedRoutes[i].meta.nav.header.parent == null) {
        // root-level route i.e. no parent
        this.headerRoutes.push({
          id: rid++,
          route: allowedRoutes[i],
        });
      }
      const { parent } = allowedRoutes[i].meta.nav.header;
      // does the parent already exist?
      let exists = false;
      for (let u = 0; u < this.headerRoutes.length; u++) {
        if (this.headerRoutes[u].parent === parent) {
          exists = true;
          break;
        }
      }
      if (!exists) {
        this.headerRoutes.push({
          id: rid++,
          parent,
          children: this.childRoutes(parent, allowedRoutes),
        });
      }
    }
  },
  methods: {
    getStyleVersion,
    async logout(): Promise<void> {
      const result = await logout();
      if (result.loggedOutSuccessfully) {
        clearServiceCache();
        this.$store.dispatch('auth/logout');
        this.$router.replace('/login');
      }
    },
    childRoutes(parent:string, allowedRoutes:Array<RouteRecordPublic>):Array<RouteRecordPublic> {
      const result:Array<RouteRecordPublic> = [];
      for (let i = 0; i < allowedRoutes.length; i++) {
        if (allowedRoutes[i].meta.nav.header.parent === parent) {
          result.push(allowedRoutes[i]);
        }
      }
      return result;
    },
  },
});
