import { getGenesysExternalAgentIdResponse, getGenesysLineIdsResponse, publicApiResponse } from '@/models/acxess-publicApi';
import { fetchWithIntercept } from '.';
import { buildAcxessAuthenticatedHeader } from './acxess';

const publicApiHost: string = process.env.VUE_APP_PUBLIC_API_URL!;

function convertGenesysRegionToNumber(region:string):number {
  switch (region) {
    case 'mypurecloud.com': {
      return 1;
    }
    case 'mypurecloud.ie': {
      return 2;
    }
    case 'mypurecloud.de': {
      return 3;
    }
    case 'mypurecloud.jp': {
      return 4;
    }
    case 'mypurecloud.com.au': {
      return 5;
    }
    case 'usw2.pure.cloud': {
      return 6;
    }
    case 'cac1.pure.cloud': {
      return 7;
    }
    case 'apne2.pure.cloud': {
      return 8;
    }
    case 'euw2.pure.cloud': {
      return 9;
    }
    case 'aps1.pure.cloud': {
      return 10;
    }
    case 'use2.us-gov-pure.cloud': {
      return 11;
    }
    case 'sae1.pure.cloud': {
      return 12;
    }
    default: {
      return 1;
    }
  }
}

export async function generateApiKey(replaceExisting:boolean): Promise<string> {
  const res = await fetchWithIntercept(`${publicApiHost}/account/generate-api-key`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...buildAcxessAuthenticatedHeader(),
    },
    body: JSON.stringify({ replaceExisting }),
  });
  return res.apiKey;
}

export async function getInternalConversationId(genesysConversationId:string): Promise<string> {
  const res = await fetchWithIntercept(`${publicApiHost}/genesys/get-internal-conversation-id`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...buildAcxessAuthenticatedHeader(),
    },
    body: JSON.stringify({ genesysConversationId }),
  });
  return res.internalConversationId;
}

export async function authenticateWithGenesysToken(token:string, region:string): Promise<string> {
  const regionNumber = convertGenesysRegionToNumber(region);
  const res = await fetchWithIntercept(`${publicApiHost}/genesys/authenticate`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      Region: regionNumber,
      AccessToken: token,
    }),
  });

  return res.token;
}

export async function getGenesysExternalAgentId(token:string, region:string): Promise<getGenesysExternalAgentIdResponse> {
  const regionNumber = convertGenesysRegionToNumber(region);
  const result = await fetchWithIntercept(`${publicApiHost}/genesys/get-external-agent-id`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify({
        AccessToken: token,
        Region: regionNumber,
      }),
    });
  if (result !== null) {
    return {
      success: result.success,
      message: result.message,
      externalAgentId: result.externalAgentId,
      internalAgentId: result.internalAgentId,
    };
  }
  throw Error(`getGenesysExternalAgentId failed with message:${result.message ?? 'n/a'}`);
}

export async function getGenesysLineIds(token: string, region: string): Promise<getGenesysLineIdsResponse> {
  const regionNumber = convertGenesysRegionToNumber(region);
  const result = await fetchWithIntercept(`${publicApiHost}/genesys/get-line-ids`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify({
        AccessToken: token,
        Region: regionNumber,
      }),
    });
  if (result !== null) {
    return {
      success: result.success,
      message: result.message,
      lineIds: result.lineIds,
    };
  }
  throw Error(`getGenesysLineIds failed with message:${result.message ?? 'n/a'}`);
}
