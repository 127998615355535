import Vue from 'vue';

export const EventBus = new Vue();

export const Channels = {
  snackbarMessage: 'snackbar-message',
  convoStatusChange: 'convo-status-change',
  convoNewInboundMessage: 'convo-new-inbound-message',
  convoNewOutboundMessage: 'convo-new-outbound-message',
  convoNewMessageMapped: 'convo-new-message-mapped',
  convoGroupChatFlagged: 'convo-group-chat-flagged',
  convoGroupChatNamed: 'convo-group-chat-named',
  signalRConnectionChanged: 'signalr-connection-changed',
  convoMessageStatusChanged: 'convo-message-status-changed',
  botSessionStarted: 'bot-session-started',
  botSessionEnded: 'bot-session-ended',
  login: 'login',
  logout: 'logout',
};
