import ExceptionInvalidToken from '@/models/exceptions/exceptionInvalidToken';
import AcxessException from '@/models/exceptions/acxessException';
import globalStore from '@/store/index';
import { loginRedirectUrl } from '@/utils';

let responseCache: Record<string, any> = {};

export function clearServiceCache():void {
  responseCache = {};
}

export function timeInMilliseconds(): number {
  return new Date().getTime();
}

export function setExpiration(expiration = 0): number {
  // default of 15 minutes, max of 30
  if (expiration === 0) {
    return new Date().getTime() + 15 * 60 * 1000;
  }
  const maxExpiration = new Date().getTime() + 30 * 60 * 1000;
  if (expiration > maxExpiration) {
    return maxExpiration;
  }
  return expiration;
}

export function invalidateServiceCache(url: string): void {
  if (responseCache[url] != null) {
    responseCache[url] = null;
  }
}

export function buildPlatformAuthenticatedHeader(): any {
  if (globalStore.getters['auth/token'].trim().length === 0) {
    // no token set, call will result in a redirect to login in fetchWithIntercept
    return {};
  }
  return {
    Accept: 'application/json',
    Authorization: `Bearer ${globalStore.getters['auth/token']}`,
  };
}

export async function fetchWithIntercept(url:string, options:any, useCache = false, reattempt = false, expiration = 0):Promise<any> {
  if (useCache && responseCache[url] != null) {
    if (responseCache[url].cacheExpiration < timeInMilliseconds()) {
      invalidateServiceCache(url);
    } else {
      return responseCache[url];
    }
  }
  const response = await fetch(url, options);
  if (response.ok) {
    if (response.status === 204) {
      return { success: true };
    }
    if (useCache) {
      responseCache[url] = await response.json();
      responseCache[url].cacheExpiration = setExpiration(expiration);
      return responseCache[url];
    }
    return response.json();
  }
  if (response.status === 401) {
    // clear the response cache
    clearServiceCache();
    // update the state
    globalStore.dispatch('auth/logout');
    if (document.location.pathname.toLocaleLowerCase().indexOf('login') === -1) {
      // redirect to login
      window.location.href = loginRedirectUrl(document.location.pathname, document.location.search);
    }
    throw new ExceptionInvalidToken('invalid token');
  } else if ((response.status === 403) && !reattempt) {
    // update token and re-attempt
    const newToken = response.headers.get('updated-access-token');
    // if we get an empty token back (really an empty header), something is wrong with the permissions.. bail
    if ((newToken == null) || (newToken.length < 1)) {
      throw new AcxessException('Received 403 without an updated-access-token header. Access denied.');
    }
    globalStore.dispatch('auth/login', { token: newToken });
    // shallow copy should work here... we just need to update the token, the rest of the original options will be by ref
    const newOptions = {
      ...options,
    };
    newOptions.headers.Authorization = `Bearer ${newToken}`;
    return fetchWithIntercept(url, newOptions, false, true);
  } else if ((response.status === 403) && reattempt) {
    throw new AcxessException('Received a 403 after updating token and re-attempting.');
  } else if (response.status === 422) {
    window.location.href = '/404';
  } else if (response.status === 400) {
    const responseData = await response.json();
    if (responseData.apiErrorCode != null) {
      throw new AcxessException(`base request exception: ${responseData.message}`, responseData.apiErrorCode, responseData);
    }
    return responseData;
  } else if (response.status === 409) {
    const responseData = await response.json();
    if (responseData.apiErrorCode != null) {
      throw new AcxessException(`base request exception: ${responseData.message}`, responseData.apiErrorCode);
    }
  }
  throw new AcxessException(`base request exception: ${response.statusText}`);
}

export async function fetchFileWithIntercept(url: string, options: any, reattempt = false): Promise<any> {
  const response = await fetch(url, options);
  if (response.ok) {
    return response;
  }
  if (response.status === 401) {
    // clear the response cache
    clearServiceCache();
    // update the state
    globalStore.dispatch('auth/logout');
    if (document.location.pathname.toLocaleLowerCase().indexOf('login') === -1) {
      // redirect to login
      window.location.href = loginRedirectUrl(document.location.pathname, document.location.search);
    }
    throw new ExceptionInvalidToken('invalid token');
  } else if ((response.status === 403) && !reattempt) {
    // update token and re-attempt
    const newToken = response.headers.get('updated-access-token');
    // if we get an empty token back (really an empty header), something is wrong with the permissions.. bail
    if ((newToken == null) || (newToken.length < 1)) {
      throw new AcxessException('Received 403 without an updated-access-token header. Access denied.');
    }
    globalStore.dispatch('auth/login', { token: newToken });
    // shallow copy should work here... we just need to update the token, the rest of the original options will be by ref
    const newOptions = {
      ...options,
    };
    newOptions.headers.Authorization = `Bearer ${newToken}`;
    return fetchFileWithIntercept(url, newOptions, true);
  } else if ((response.status === 403) && reattempt) {
    throw new AcxessException('Received a 403 after updating token and re-attempting.');
  } else if (response.status === 422) {
    window.location.href = '/404';
  } else if (response.status === 400) {
    const responseData = await response.json();
    if (responseData.apiErrorCode != null) {
      throw new AcxessException(`base request exception: ${responseData.message}`, responseData.apiErrorCode, responseData);
    }
    return responseData;
  } else if (response.status === 409) {
    const responseData = await response.json();
    if (responseData.apiErrorCode != null) {
      throw new AcxessException(`base request exception: ${responseData.message}`, responseData.apiErrorCode);
    }
  }
  throw new AcxessException(`base request exception: ${response.statusText}`);
}
