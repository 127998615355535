export enum AuthViaAuthenticationScope {
  PaymentRequest = 1,
  CreateAgent = 2,
  PortalLogin = 3,
}

export interface authViaLineIntegration {
  id: string,
  customerLineId: string,
}
