import { stopBlinkingTitle } from '@/utils';

export const acxessConversations = {
  namespaced: true,
  state: {
    activeGroupChatId: '',
    unreadGroupChatIds: [],
    signalRConnected: false,
  },
  mutations: {
    activeGroupChatId(state:any, newGroupChatId:string):void {
      state.activeGroupChatId = newGroupChatId;
    },
    setUnreadGroupChatId(state: any, unreadGroupChatId: string): void {
      if (!state.unreadGroupChatIds.includes(unreadGroupChatId)) {
        state.unreadGroupChatIds.push(unreadGroupChatId);
      }
    },
    deleteUnreadGroupChatId(state: any, readGroupChatId: string): void {
      for (let i = 0; i < state.unreadGroupChatIds.length; i++) {
        if (state.unreadGroupChatIds[i] === readGroupChatId) {
          state.unreadGroupChatIds.splice(readGroupChatId, 1);
        }
      }
    },
    signalRConnected(state: any, connected: boolean): void {
      state.signalRConnected = connected;
    },
  },
  getters: {
    activeGroupChatId: (state: any) => state.activeGroupChatId,
    unreadGroupChatIds: (state: any) => state.unreadGroupChatIds,
    signalRConnected: (state: any) => state.signalRConnected,
  },
  actions: {
    activeGroupChatId: (context:any, params: { groupChatId:string }) => {
      context.commit('activeGroupChatId', params.groupChatId);
    },
    setUnreadGroupChatId: (context: any, params: { groupChatId: string, isRead: boolean }) => {
      if (params.isRead) {
        context.commit('deleteUnreadGroupChatId', params.groupChatId);
        if (context.getters.unreadGroupChatIds.length === 0) {
          stopBlinkingTitle('New Message');
        }
      } else {
        context.commit('setUnreadGroupChatId', params.groupChatId);
      }
    },
    signalRConnected: (context: any, params: { isConnected: boolean }) => {
      context.commit('signalRConnected', params.isConnected);
    },
  },
};
