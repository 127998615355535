import { isNullOrEmpty } from '@/utils';
import { LineIntelligenceType, MobileCarrierType } from './platform-lines';

export enum acxessProductIds {
  Conversations = 1,
  Administration = 2,
  Blast = 3,
  Responders = 4,
  Reporting = 5,
  RingCentral = 7,
  PublicApi = 8,
  Nice = 9,
  InternalSystemsApi = 10,
  Genesys = 11,
  TestRunnerApi = 12,
  ChatBot = 13,
  LineIntelligence = 14,
  Translation = 15,
  Payments = 16,
}

export enum acxessAdministrationFeatureIds {
  UserManagement = 1,
  LineManagement = 2,
  ContactManagement = 3,
  ConversationsManagement = 4,
  AccountManagement = 5,
  TwoFactorAuth = 6,
}

export enum acxessBlastFeatureIds {
  StandardCampaign = 1,
  SurveyCampaign = 2,
  DialerCampaign = 3,
}

export enum acxessConversationsFeatureIds {
  GroupMessaging = 1,
}

export enum acxessRespondersFeatureIds {
  ResponseTemplates = 1,
  KeywordResponders = 2,
  AutoReplies = 3,
  QrCodeGenerate = 4,
}

export enum acxessCRUDyFeatureIds {
  Accounts = 1,
  Users = 2,
  Lines = 3,
  Products = 4,
  Queues = 5,
}

export enum acxessFeatureAccess {
  View = 1,
  Change = 2,
  ViewAndChange = 3,
  Execute = 4,
  ViewAndExecute = 5,
  ChangeAndExecute = 6,
  ViewAndChangeAndExecute = 7,
}

export enum acxessConversationStatus {
  None = 0,
  Unclaimed = 1,
  Claimed = 1 << 1,
  Open = 1 << 2,
  Closed = 1 << 3,
}

export enum acxessUserStatus {
  Active = 1,
  Deleted = 2,
  Pending = 3,
}

export enum acxessSortDirection {
  None = 0,
  Ascending,
  Descending,
}

export enum acxessOptStatus {
  None = 0,
  OptIn = 1,
  OptOut = 2,
  OptOutOfAccount = 3,
}

export enum acxessContactSortBy {
  None = 0,
  MobileNumber,
  FirstName,
  LastName,
  Email,
  Created
}

export enum acxessLineIntegrationPlatformAppOptions {
  None = 0,
  Text = 1,
  Attachments = 1 << 1,
}

export interface acxessPermissionSet {
  productId: acxessProductIds,
  featureId?: number,
  accessLevels?: Array<acxessFeatureAccess>
}

export enum acxessContactListSortBy {
  None = 0,
  Name,
  ContactCount,
  Created,
  IsEnabled
}

export enum BillingPlan {
  Message = 1,
  User = 2
}

export enum BillingTerm {
  Unknown = 0,
  Monthly = 1,
  Annually = 2,
  Usage = 3
}

export enum acxessContactImportType {
  ContactImport = 1,
  OptInOutImport = 2,
}

export enum acxessContactImportStatus
{
  Queued = 1,
  Running = 2,
  Completed = 3,
  Failed = 4,
  PartialFailure = 5,
}

export enum acxessTimeResponderType {
  HoursOfOperation = 1,
  CustomSchedule = 2,
  Holiday = 3,
}

export enum Day {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export enum TranslationReferenceType {
  OutgoingMessage = 1,
  IncomingMessage = 2,
  Responder = 3,
  ResponseTemplate = 4,
  CampaignMessage = 5,
}

export interface acxessContactImportContent {
  id: string,
  contactImportType: acxessContactImportType,
  contactImportTypeName: string,
  contactImportStatus: acxessContactImportStatus,
  contactImportStatusName: string,
  contactImportErrorFileName: string,
  contactImportFileName: string,
  customerFileName?: string,
  created?:Date,
  updated?:Date,
}

export interface acxessBillingPlan {
  planType: BillingPlan,
  billingTerm: BillingTerm,
  planCount?: number,
  billingDay: number,
  startDate: Date,
  endDate?: Date,
  isActive: boolean,
  currentBillingWindowStart: Date,
  currentBillingWindowEnd: Date,
}

export interface acxessMessagePlanUsage {
  used: number,
  scheduled: number,
  total: number,
}

export interface messagePlanAndUsage {
  billingPlan: acxessBillingPlan,
  used: number,
  scheduled: number,
  total: number,
}

export interface userPlanAndUsage {
  billingPlan: acxessBillingPlan,
  userCount: number,
}

export interface acxessPagedResponse<PayloadType> {
  page:number,
  totalPages:number,
  totalItems:number,
  message:string,
  success:boolean,
  payload:PayloadType,
}

export interface acxessContact {
  publicId: number,
  id?: string,
  mobileNumber:string,
  lineId?:string,
  email?:string,
  firstName?:string,
  lastName?: string,
  note?: string,
  contactListIds?: Array<string>,
  created?:Date,
  updated?:Date,
  lineIntelligenceType?:LineIntelligenceType,
  mobileCarrierType?:MobileCarrierType,
  isBlocked?: boolean,
}

export interface acxessContactList {
  id: string,
  publicId: number,
  name:string,
  contactCount?:number,
  isEnabled: boolean,
  isSystemGenerated: boolean,
  isDeleted: boolean,
  created: Date,
  contacts?: Array<acxessContact>,
  contactsToRemove?: Array<number>,
  contactsToAdd?: Array<number>,
}

export interface acxessAddContactList {
  id?: string,
  name: string,
  contactPublicIds?: Array<number>,
  isEnabled: boolean,
  includeContacts: boolean,
}

export interface acxessRole {
  name:string,
  id:number,
}

export interface acxessLinePermission {
  lineID:string,
  access:number,
}

export interface acxessFeature {
  featureId:number,
  access:number,
  name:string,
  accessType:acxessFeatureAccess,
}

export interface acxessProduct {
  productType:acxessProductIds,
  productId:number,
  access:number,
  featureAccess:Array<acxessFeature>,
}

export interface acxessSession {
  success:boolean,
  message:string,
  token:string,
  permissions:any,
  sessionId:number,
  email:string,
  nameId:number,
  accountId:number,
  twoFactorRequired:boolean,
  twoFactorSetupRequired:boolean,
}

export interface acxessUser {
  userId:string,
  publicId:number,
  email:string,
  firstName?:string,
  lastName?:string,
  role:acxessRole,
  products:string,
  lines:string,
  status:acxessUserStatus,
  created:Date,
  updated?:Date,
  accountId:string,
  productAccess?:Array<acxessProduct>,
  lineAccess?:Array<acxessLinePermission>,
}

export interface acxessEditUser {
  publicId: number,
  username?: string,
  email: string,
  firstName: string,
  lastName: string,
  role?: acxessRole,
  status?: acxessUserStatus,
}

export interface acxessUpdateUserPassword {
  publicId: number,
  password: string,
}

export interface acxessLineUser {
  userId:string,
  publicId: number,
  email: string,
  firstName?: string,
  lastName?: string,
  status: acxessUserStatus
}

export interface acxessLineIds {
  lineIds: Array<string>,
}

export interface acxessAssignLine {
  lineId: string
  userIds?: Array<string>,
  userIdsToAdd?: Array<string>,
  userIdsToRemove?: Array<string>,
}

export interface acxessLine {
  lineId:string,
  phoneNumber:string,
  lineName?:string,
  created:Date,
  userCount?:number,
  responderCount?:number,
  isEnabled: boolean,
  isGroupMessagingEnabled: boolean,
  isBotEnabled: boolean,
  optedOut?: boolean,
}

export interface acxessKeywordResponder {
  keyword: string,
  name: string,
  autoReplyBody?: string,
  attachmentUrl?: string,
  description?: string,
  lineCount?: number,
  lines?: Array<acxessLine>,
  lineIds?: Array<string>,
  removeLineIds?: Array<string>,
  responderAction?: acxessOptStatus,
  created: Date,
  updated?: Date,
  isEnabled: boolean,
  publicId: number,
}

export interface acxessSaveKeywordResponderResult {
  message: string,
  savedResponder: boolean,
  addedAllLines: boolean,
  keywordResponder: acxessKeywordResponder,
}

export interface acxessTimeSelect {
  h: string,
  mm: string,
  A: string,
}

export interface acxessResponderTimes {
  sundayStart: number,
  sundayEnd: number,
  mondayStart: number,
  mondayEnd: number,
  tuesdayStart: number,
  tuesdayEnd: number,
  wednesdayStart: number,
  wednesdayEnd: number,
  thursdayStart: number,
  thursdayEnd: number,
  fridayStart: number,
  fridayEnd: number,
  saturdayStart: number,
  saturdayEnd: number,
  isInverse: boolean,
}

export interface acxessAutoResponder {
  name: string,
  autoReplyBody?: string,
  attachmentUrl?: string | null,
  description?: string,
  lineCount?: number,
  lines?: Array<acxessLine>,
  lineIds?: Array<string>,
  removeLineIds?: Array<string>,
  created: Date,
  updated?: Date,
  isEnabled: boolean,
  publicId: number,
  sundayStart?: number,
  sundayEnd?: number,
  mondayStart?: number,
  mondayEnd?: number,
  tuesdayStart?: number,
  tuesdayEnd?: number,
  wednesdayStart?: number,
  wednesdayEnd?: number,
  thursdayStart?: number,
  thursdayEnd?: number,
  fridayStart?: number,
  fridayEnd?: number,
  saturdayStart?: number,
  saturdayEnd?: number,
  isInverse: boolean,
  isHoliday: boolean,
  holidayDate: Date,
}

export interface axcessInviteRequest {
  emailAddresses: Array<string>,
  role: number,
  ProductAccess: Array<acxessProduct>,
  LineAccess: Array<acxessLinePermission>,
}

export interface acxessSignupResult {
  success:boolean,
  message:string
}

export interface acxessLogoutResult {
  success: boolean,
  loggedOutSuccessfully: boolean,
  message: string
}

export interface acxessForgotPasswordResult {
  forgotPasswordSuccessful:boolean,
  message:string
}

export interface acxessResetPasswordResult {
  success:boolean,
  message:string
}

export interface acxessValidatePasswordResetTokenResult {
  isValid:boolean,
  message:string
}

export interface acxessValidateSignupTokenResult {
  success:boolean,
  message:string
}

export interface acxessUpdateContactListNameResult {
  success:boolean,
  message:string
}

export interface acxessResponseTemplate {
  publicId?:number,
  name:string,
  message:string,
  attachmentUrl?: string,
  lineIds?:Array<string>,
  isEnabled:boolean,
  lines?:Array<acxessLine>
}

export interface acxessContactImport {
  contactListPublicIds: Array<number>,
  columnMapping: Array<any>,
  id?: string,
  importFile: any,
}

export interface acxessColumnMap {
  id: number,
  title: string,
}

export interface acxessUploadAttachmentsResponse {
  contentType:string,
  originalFileName:string,
  url:string
}

export interface acxessEnqueueResponse {
  queuedSuccessfully:boolean,
  queuedMessageId:string
}

export interface acxessAccountSendWindowResponse {
  timezone: string,
  sendWindowStart: string,
  sendWindowEnd: string
}

export interface acxessUserSignature {
  isSignatureEnabled: boolean,
  signature: string
}

export interface scheduledMessageContact {
  firstName: string,
  lastName: string,
  phoneNumber: string,
}

export interface scheduledMessage {
  scheduledMessageId: string,
  messageBody: string,
  attachmentUrl: string,
  customerLineId: string,
  contactId: string,
  externalLineId: string,
  scheduledMessageState: number,
  seriesId: string,
  scheduledTime: Date,
  prerequisiteId: string,
  productId: number,
  contact: scheduledMessageContact,
}

export interface accountSettings {
  timezone: string,
  sendWindowStart: string,
  sendWindowEnd: string,
  conversationAutoCloseMinutes?: number,
  twoFactorRequired: boolean,
}

export interface updateAccountSettings {
  timezone: string,
  sendWindowStart?: number,
  sendWindowEnd?: number,
  conversationAutoCloseMinutes?: number,
  twoFactorRequired: boolean,
}

export interface saveAccountCustomContactFieldContent {
  id?: string,
  fieldName: string,
}

export interface accountCustomContactField {
  id: string,
  accountId: string,
  fieldName: string,
  created: Date,
  updated?: Date,
}

export interface saveCustomContactFieldContent {
  accountCustomContactFieldId: string,
  text: string
}

export interface customContactField {
  id: string,
  contactId: string,
  accountCustomContactFieldId: string,
  fieldName: string,
  text: string,
  created: Date,
  updated?: Date,
}

export function axcessContactInitials(contact:acxessContact):string | undefined {
  if (isNullOrEmpty(contact.firstName)) return undefined;
  let result = contact.firstName![0];
  if (!isNullOrEmpty(contact.lastName)) {
    result += contact.lastName![0];
  } else if (contact.firstName!.length > 1) {
    result += contact.firstName![1];
  }
  return result?.toUpperCase();
}

export function acxessContactHasName(contact:acxessContact):boolean {
  return !isNullOrEmpty(contact.firstName) || !isNullOrEmpty(contact.lastName);
}

export function acxessContactName(contact:acxessContact):string | undefined {
  let result;
  if (!isNullOrEmpty(contact.firstName)) {
    result = contact.firstName!;
  }
  if (!isNullOrEmpty(contact.lastName)) {
    result += ` ${contact.lastName}`;
  }
  return result;
}

export function matchPermissionSet(set:acxessPermissionSet, globalStore:any):boolean {
  const sessionProducts = globalStore.getters['auth/permissions'].ProductAccess;
  // product restriction but no products?!?!?
  if (sessionProducts == null) return false;
  const product = sessionProducts.find((x: any) => x.ProductId === set.productId);
  if (product == null) return false;
  // check access levels for the feature - if defined
  if (set.featureId != null && set.accessLevels != null) {
    const feature = product.FeatureAccess.find((x: any) => x.FeatureId === set.featureId);
    if (feature == null) {
      // no matching feature
      return false;
    }
    for (let i = 0; i < set.accessLevels.length; i++) {
      if (feature.Access === set.accessLevels[i]) {
        return true;
      }
    }
    // no matching feature/access pair
    return false;
  }
  // check access levels for the product - if defined
  if (set.accessLevels != null && product.Access != null) {
    for (let i = 0; i < set.accessLevels.length; i++) {
      if (product.Access === set.accessLevels[i]) {
        return true;
      }
    }
    // no matching product/access pair
    return false;
  }
  // allows for qualifying with just a product
  return true;
}

export function matchUserPermissionSet(set: acxessPermissionSet, user: acxessUser): boolean {
  const userProducts = user.productAccess;
  // product restriction but no products?!?!?
  if (userProducts == null) return false;
  const product = userProducts.find((x: any) => x.productId === set.productId);
  if (product == null) return false;
  // check access levels for the feature - if defined
  if (set.featureId != null && set.accessLevels != null) {
    const feature = product.featureAccess.find((x: any) => x.featureId === set.featureId);
    if (feature == null) {
      // no matching feature
      return false;
    }
    for (let i = 0; i < set.accessLevels.length; i++) {
      if (feature.access === set.accessLevels[i]) {
        return true;
      }
    }
    // no matching feature/access pair
    return false;
  }
  // check access levels for the product - if defined
  if (set.accessLevels != null && product.access != null) {
    for (let i = 0; i < set.accessLevels.length; i++) {
      if (product.access === set.accessLevels[i]) {
        return true;
      }
    }
    // no matching product/access pair
    return false;
  }
  // allows for qualifying with just a product
  return true;
}
