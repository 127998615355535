import globalStore from '@/store/index';
import { customDomain, shortLink } from '@/models/short-links';
import { buildPlatformAuthenticatedHeader, fetchWithIntercept } from '.';

const platformApiHost: string = process.env.VUE_APP_ACXESS_API_URL!;

export async function getAccountShortLinks(): Promise<Array<shortLink>> {
  const result = await fetchWithIntercept(`${platformApiHost}/shortLink/short-links`,
    {
      method: 'GET',
      headers: {
        ...buildPlatformAuthenticatedHeader(),
      },
    });
  if (result.success) {
    return result.shortLinks;
  }
  throw Error('Error getting short links.');
}

export async function createShortLink(linkName: string, destinationUrl: string): Promise<shortLink> {
  const result = await fetchWithIntercept(`${platformApiHost}/shortLink/create`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildPlatformAuthenticatedHeader(),
      },
      body: JSON.stringify({
        linkName,
        destinationUrl,
      }),
    });
  if (result.success) {
    return result.shortLink;
  }
  throw Error('Error creating short link.');
}

export async function updateShortLink(id: string, linkName?: string, isActive?: boolean): Promise<boolean> {
  const result = await fetchWithIntercept(`${platformApiHost}/shortLink/${id}`,
    {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        ...buildPlatformAuthenticatedHeader(),
      },
      body: JSON.stringify({
        linkName,
        isActive,
      }),
    });
  if (result.success) {
    return true;
  }
  throw Error('Error getting short links.');
}

export async function getAccountCustomDomain(): Promise<customDomain> {
  const result = await fetchWithIntercept(`${platformApiHost}/shortLink/custom-domain`,
    {
      method: 'GET',
      headers: {
        ...buildPlatformAuthenticatedHeader(),
      },
    });
  if (result.success) {
    return {
      domain: result.customDomain?.domain ?? '',
      isVerified: result.customDomain?.isVerified ?? false,
    };
  }
  throw Error('Error getting custom domain.');
}

export async function saveCustomDomain(domain: string): Promise<customDomain> {
  const result = await fetchWithIntercept(`${platformApiHost}/shortLink/save-custom-domain`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildPlatformAuthenticatedHeader(),
      },
      body: JSON.stringify({
        domain,
      }),
    });
  if (result.success) {
    globalStore.dispatch('auth/accountCustomDomain', { accountCustomDomain: result.customDomain });
    return result.customDomain;
  }
  throw Error('Error saving custom domain.');
}

export async function verifyCustomDomain(domain: string): Promise<boolean> {
  const result = await fetchWithIntercept(`${platformApiHost}/shortLink/verify-custom-domain`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildPlatformAuthenticatedHeader(),
      },
      body: JSON.stringify({
        domain,
      }),
    });
  if (result.success) {
    globalStore.dispatch('auth/accountCustomDomain', { accountCustomDomain: { domain, isVerified: true } });
    return true;
  }
  throw Error('Error verifying custom domain.');
}
