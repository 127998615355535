import { getComputedCssVarColor } from '@/utils';

export enum acxessReportStatusType {
    Unknown = 0,
    Queued = 1,
    Running = 2,
    Completed = 3,
    Failed = 4,
}

export enum acxessReportTypeEnum {
    Unknown = 0,
    LineDetailReport = 1,
    AccountDetailReport = 2,
    ConversationHistoryReport = 3,
    AccountSummaryVisualReport = 4,
    LineSummaryVisualReport = 5,
    LineOptInOutReport = 6,
    ResponderUsageCountReport = 7,
    BlastSummaryReport = 8,
    BlastDetailReport = 9,
    DetailedLinkClickReport = 10,
    SummaryLinkClickReport = 11,
    TranslationUsageReport = 12
}

export enum acxessReportGraphTypeEnum {
    BigNumber,
    LineBar,
    Table,
    Doughnut,
}

export enum acxessReportFieldStatus {
    Default,
    Success,
    Fail,
    Primary,
    Warning,
    Secondary,
    Accent,
    AccentLight,
    FailDark,
}

export enum reportPeriodType {
    Rolling7Day = 1,
    Rolling30Day = 2,
    LastFullMonth = 3
}

export enum reportCategoryTypeEnum {
  Unknown,
  AccountLineUsage,
  AccountLineOptInOut,
  ConversationHistory,
  VisualSummary,
  ResponderUsage,
  BlastSummaryAndDetail,
  LinkAnalytics,
  TranslationUsage
}

export interface acxessReportFieldBigNumber {
    label: string,
    value: number,
    status: acxessReportFieldStatus,
}

export interface acxessReportLineBarDataSet {
    label: string,
    set: Array<number>,
    status: acxessReportFieldStatus,
    stack?: string,
    type: 'bar' | 'line' | 'scatter' | 'bubble' | 'pie' | 'doughnut' | 'polarArea' | 'radar' | undefined,
}

export interface acxessReportDoughnutPieDataSet {
    label: string,
    set: Array<number>,
    status: acxessReportFieldStatus | Array<acxessReportFieldStatus>,
}

export interface acxessReportChartData<DataSetType> {
    labels: Array<string>,
    datasets: DataSetType,
    header?: string,
}

export interface acxessReportFieldTableCellValue {
    value: string,
    status: acxessReportFieldStatus,
}

export interface acxessReportTableData {
    headers: Array<string>,
    rows: Array<Array<acxessReportFieldTableCellValue>>;
}

export interface acxessGraph {
    header?: string,
    type: acxessReportGraphTypeEnum,
    data: Array<acxessReportFieldBigNumber> | acxessReportChartData<Array<acxessReportLineBarDataSet>> | acxessReportChartData<Array<acxessReportDoughnutPieDataSet>> | acxessReportTableData,
}

export interface acxessReportType {
    id: string,
    isViewable: boolean,
    description: string,
    name: string,
    reportType: acxessReportTypeEnum,
}

export interface acxessReportCategoryType {
    id: string,
    description: string,
    name: string,
    reportCategoryType: reportCategoryTypeEnum,
    reportTypes: Array<acxessReportType>,
}

export interface acxessReport {
    id: string,
    startDate: Date,
    endDate: Date,
    reportType: number,
    reportName: string,
    reportDescription: string,
    isViewable: boolean,
    reportStatus: acxessReportStatusType,
    reportStatusName: string,
    reportFileUrl: string,
    created: Date,
    updated: Date,
}

export interface acxessReportData {
    title: string,
    startDate: Date,
    endDate: Date,
    graphs: Array<acxessGraph>,
}

export interface dashboardBigNumbers {
    bigNumbers: acxessGraph
    lastUpdated: Date,
}

export function getCssClassFromFieldStatus(status:acxessReportFieldStatus, targetText = false):string {
  switch (status) {
    case acxessReportFieldStatus.Success:
      return (targetText) ? 'success--text' : '--v-success-base';
    case acxessReportFieldStatus.Fail:
      return (targetText) ? 'error--text' : '--v-error-base';
    case acxessReportFieldStatus.FailDark:
      return (targetText) ? 'error--text text--darken-4' : '--v-error-darken4';
    case acxessReportFieldStatus.Primary:
      return (targetText) ? 'primary--text' : '--v-primary-base';
    case acxessReportFieldStatus.Secondary:
      return (targetText) ? 'secondary--text' : '--v-secondary-base';
    case acxessReportFieldStatus.Accent:
      return (targetText) ? 'accent--text' : '--v-accent-base';
    case acxessReportFieldStatus.Warning:
      return (targetText) ? 'warning--text' : '--v-warning-base';
    case acxessReportFieldStatus.AccentLight:
      return (targetText) ? 'gray--text' : '--v-graybkg-darken1';
    default:
      return (targetText) ? 'darkgray-darken4--text' : '--v-darkgray-darken4';
  }
}

export function getCssColorFromFieldStatus(status:acxessReportFieldStatus):string {
  return getComputedCssVarColor(getCssClassFromFieldStatus(status));
}

export function getDataSetColor(set:acxessReportDoughnutPieDataSet):Array<string> {
  const result = [];
  if (typeof set.status === 'number') {
    result.push(getCssColorFromFieldStatus(set.status as acxessReportFieldStatus));
  } else {
    (set.status as Array<acxessReportFieldStatus>).forEach((s) => {
      result.push(getCssColorFromFieldStatus(s));
    });
  }
  return result;
}
