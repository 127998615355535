import { BlastFiltersType } from '@/models/blast/campaign';

export const filters = {
  namespaced: true,
  state: {
    blast: {
      searchTerm: '',
      date: [],
      lines: [],
      statuses: [],
      types: [],
    } as BlastFiltersType,
  },
  mutations: {
    blast: (state: any, params: BlastFiltersType) => {
      if (params) {
        state.blast = params;
      }
    },
  },
  getters: {
    blast: (state: any) => state.blast,
  },
  actions: {
    blast: (context: any, params: BlastFiltersType) => {
      context.commit('blast', params);
    },
    clearBlastFilters: (context: any) => {
      context.commit('blast', {
        searchTerm: '',
        date: [],
        lines: [],
        statuses: [],
        types: [],
      });
    },
  },
};
