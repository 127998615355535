import { buildAcxessAuthenticatedHeader } from '@/services/acxess';
import Conversation, {
  ConversationSearchStatusFlags,
  mapApiConversationToConversation,
  mapApiMessageToMessage,
  mapEventConversationToConversation,
} from '@/models/conversations/conversation';
import Message, { MessageStatus } from '@/models/conversations/message';
import GroupChat from '@/models/conversations/groupChat';
import globalStore from '@/store/index';
import { Channels, EventBus } from '@/eventbus';
import {
  acxessContactHasName,
  acxessContactName,
  acxessEnqueueResponse,
  scheduledMessage,
} from '@/models/acxess';
import { isNullOrEmpty, blinkTitle } from '@/utils';
import cloneDeep from 'lodash.clonedeep';
import Agent from '@/models/conversations/agent';
import { streamGroupChatHistory } from './signalr';
import { fetchWithIntercept } from '.';
import { logAcxessException, logType } from './logger';
import { showNotification } from './notifications';

const conversationApiHost: string = process.env.VUE_APP_CONVERSATION_API_URL!;

const histories: Record<string, Array<Message>> = {};
const agentCache: Record<string, Agent> = {};

function addNewMessageToCache(groupChatId: string, message: Message): void {
  if (histories[groupChatId] != null) {
    const foundMessage = histories[groupChatId].find((x: Message) => x.id === message?.id);
    if (foundMessage == null) {
      // instead of always unshifting a new message, we want to make sure its in the right spot
      // if it has the most recent time of the chat messages, it will be put at index 0

      // when getting chat history, const index return -1 if the message is older than...
      // any message currently in the histories[groupChatId] array, so we push it last in the array
      const index = histories[groupChatId].findIndex((x) => x.sent!.getTime() < message.sent!.getTime());
      if (index < 0) {
        histories[groupChatId].push(message);
      } else {
        histories[groupChatId].splice(index, 0, message);
      }
    }
  } else {
    histories[groupChatId] = [message];
  }
}

/* Takes the list of cached group chat Ids and checks to ensure their agent name matches the store */
function updateCacheWithNewAgentName(cachedGroupChatIds: string[]): void {
  const agentId = globalStore.getters['auth/agentId'];
  const firstName = globalStore.getters['auth/session'].given_name;
  const lastName = globalStore.getters['auth/session'].family_name;

  /* If the agent is cached, ensure its first and last name are updated (for history steaming) */
  if (agentCache[agentId] != null) {
    agentCache[agentId].firstName = firstName;
    agentCache[agentId].lastName = lastName;
  }

  for (let i = 0; i < cachedGroupChatIds.length; i++) {
    const groupChat = histories[cachedGroupChatIds[i]];
    /* Get the outbound messages sent by the agent and update their author's first and last names (if they're different) */
    const outboundAgentMessages = groupChat.filter((x) => x.inbound === false && x.author?.id === agentId);
    if (outboundAgentMessages.length > 0) {
      const authorFirstName = outboundAgentMessages[0].author!.firstName;
      const updatedFirstName = !isNullOrEmpty(authorFirstName) && authorFirstName !== firstName;
      if (updatedFirstName) {
        for (let j = 0; j < outboundAgentMessages.length; j++) {
          outboundAgentMessages[j].author!.firstName = firstName;
        }
      }

      const authorLastName = outboundAgentMessages[0].author!.lastName;
      const updatedLastName = !isNullOrEmpty(authorLastName) && authorLastName !== lastName;
      if (updatedLastName) {
        for (let j = 0; j < outboundAgentMessages.length; j++) {
          outboundAgentMessages[j].author!.lastName = lastName;
        }
      }
    }
  }
}

async function getAgentsByAgentIds(internalAgentIds: Array<string>): Promise<Array<Agent>> {
  const result:Array<Agent> = [];
  const uncachedAgents:Array<string> = [];
  // check for cached agents
  for (let i = 0; i < internalAgentIds.length; i++) {
    if (agentCache[internalAgentIds[i]] != null) {
      result.push(agentCache[internalAgentIds[i]]);
      break;
    }
    uncachedAgents.push(internalAgentIds[i]);
  }
  if (uncachedAgents.length === 0) {
    return result;
  }
  const apiResult = await fetchWithIntercept(`${conversationApiHost}/conversations/agents-by-ids`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify({
        agentIds: uncachedAgents,
      }),
    });
  if (apiResult.success) {
    apiResult.agents.forEach((agent: Agent) => {
      agentCache[agent.id] = agent;
      result.push(agent);
    });
    return result;
  }
  throw Error(`Error message: ${apiResult.message ?? 'n/a'}`);
}

export async function getAgentId():Promise<string> {
  const result = await fetchWithIntercept(`${conversationApiHost}/Conversations/agent-by-token`,
    {
      method: 'GET',
      headers: {
        ...buildAcxessAuthenticatedHeader(),
      },
    });
  if (result.success && result.agentId != null) {
    return result.agentId;
  }
  throw Error(`getAgentId failed message:${result.message ?? 'n/a'}`);
}

export async function searchConversations(page:number, conversationStatus:ConversationSearchStatusFlags, lines:Array<string> = [], contactSearch = '', pageSize = 20):Promise<Array<Conversation>> {
  const result = await fetchWithIntercept(`${conversationApiHost}/conversations/sidebar-search`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...buildAcxessAuthenticatedHeader(),
    },
    body: JSON.stringify({
      Page: page,
      ResultsPerPage: pageSize,
      ContactSearchTerm: contactSearch,
      LineIds: lines,
      ConversationStatus: conversationStatus,
    }),
  });
  if (result.success) {
    const cachedGroupChatIds = result.conversations?.map((x: any) => x.conversation?.groupChatId).filter((x: any) => histories[x] != null);
    if (cachedGroupChatIds.length > 0) {
      updateCacheWithNewAgentName(cachedGroupChatIds);
    }

    if (result.conversations == null) {
      return [];
    }
    return result.conversations.map((x:any) => mapApiConversationToConversation(x));
  }
  throw Error(`sidebar-search failed message:${result.message ?? 'n/a'}`);
}

export async function claimConversation(conversationId: string, agentId?: string): Promise<void> {
  let validAgentId;
  if (agentId == null) {
    validAgentId = globalStore.getters['auth/agentId'];
  } else {
    validAgentId = agentId;
  }
  const result = await fetchWithIntercept(`${conversationApiHost}/conversations/assign-agent-to-conversation`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify({
        AgentId: validAgentId,
        ConversationId: conversationId,
      }),
    });
  if (result.success) {
    return;
  }
  throw Error(`claimConversation failed message:${result.message ?? 'n/a'}`);
}

export async function getAgentsByUserIds(usersIds: Array<string>): Promise<Array<Agent>> {
  const result = await fetchWithIntercept(`${conversationApiHost}/conversations/agents-by-external-ids`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify({
        ExternalAgentIds: usersIds,
      }),
    });
  if (result.success) {
    return result.agents;
  }
  throw Error(`Error message: ${result.message ?? 'n/a'}`);
}

export async function getConversation(convoId: string): Promise<Conversation> {
  if (isNullOrEmpty(convoId)) {
    throw Error('Must provide valid id');
  }
  const result = await fetchWithIntercept(`${conversationApiHost}/conversations/conversation/${convoId}`,
    {
      method: 'GET',
      headers: {
        ...buildAcxessAuthenticatedHeader(),
      },
    });
  if (result.success) {
    // assign a date to lastMessageTime so the map call is happy..
    // the only use for this method SO FAR is in the chat Popout.vue..
    // and last message time is not used there so it is not important
    if (result.conversation.lastMessageTime == null) {
      result.conversation.lastMessageTime = new Date();
    }
    return mapApiConversationToConversation(result);
  }
  throw Error('Failed to retrieve conversation.');
}

export async function releaseConversation(conversationId:string):Promise<void> {
  const result = await fetchWithIntercept(`${conversationApiHost}/conversations/release-conversation`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify({
        ConversationId: conversationId,
      }),
    });
  if (result.success) {
    return;
  }
  throw Error(`releaseConversation failed with message:${result.message ?? 'n/a'}`);
}

export async function releaseAgentConversations(publicUserId: number): Promise<void> {
  const result = await fetchWithIntercept(`${conversationApiHost}/conversations/release-agent-conversations/${publicUserId}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
    });
  if (result.success) {
    return;
  }
  throw Error(`Failed to release agent conversations with message:${result.message ?? 'n/a'}`);
}

export async function startConversation(customerLineId:string, contactPhoneNumbers:Array<string>, messageBody?:string, attachmentUrls?:Array<string>, groupName?:string):Promise<Conversation> {
  const result = await fetchWithIntercept(`${conversationApiHost}/conversations/new-conversation`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify({
        CustomerLineId: customerLineId,
        ContactPhoneNumbers: contactPhoneNumbers,
        MessageBody: messageBody,
        AttachmentUrls: attachmentUrls,
        GroupName: groupName,
      }),
    });
  if (result.success) {
    if (result.conversation.enqueueResponse != null && result.conversation.enqueueResponse?.queuedSuccessfully) {
      const created = new Date(new Date().toLocaleString('en-US', { timeZone: 'UTC' }));
      const clonedConvo = cloneDeep(result.conversation);
      const newMessage = {
        status: MessageStatus.Sending,
        id: result.conversation.enqueueResponse.queuedMessageId,
        fullBody: messageBody,
        messageAttachments: attachmentUrls?.map((x) => ({ url: x })),
        isOutgoing: true,
        creatorLineId: customerLineId,
        author: {
          firstName: globalStore.getters['auth/session'].given_name,
          lastName: globalStore.getters['auth/session'].family_name,
          lineId: customerLineId,
          phoneNumber: result.conversation.customerLines[0].phoneNumber,
          publicId: globalStore.getters['auth/agentId'],
        },
        created,
      };
      EventBus.$emit(Channels.convoNewOutboundMessage, {
        conversation: clonedConvo,
        message: newMessage,
        customerLine: newMessage.author,
        created,
      });
    }
    return mapApiConversationToConversation(result);
  }
  throw Error(`startConversation failed with message:${result.message ?? 'n/a'}`);
}

export async function startIntegrationConversation(customerLineId:string, contactPhoneNumbers:Array<string>, messageBody?:string, attachmentUrls?:Array<string>, groupName?:string, agentId?:string):Promise<void> {
  const result = await fetchWithIntercept(`${conversationApiHost}/conversations/new-integration-conversation`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify({
        CustomerLineId: customerLineId,
        ContactPhoneNumbers: contactPhoneNumbers,
        MessageBody: messageBody,
        AttachmentUrls: attachmentUrls,
        GroupName: groupName,
        ExternalAgentId: agentId,
      }),
    });
  if (result.success) {
    return;
  }
  throw Error(`startIntegrationConversation failed with message:${result.message ?? 'n/a'}`);
}

export async function reOpenConversation(customerLineId: string, contactPhoneNumbers: Array<string>): Promise<boolean> {
  const result = await fetchWithIntercept(`${conversationApiHost}/conversations/re-open`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify({
        CustomerLineId: customerLineId,
        ContactPhoneNumbers: contactPhoneNumbers,
      }),
    });
  if (result.success) {
    return true;
  }
  throw Error(`re-open conversation failed with message: ${result.message ?? 'n/a'}`);
}

export async function closeConversation(conversationId:string, groupChatId:string):Promise<void> {
  const result = await fetchWithIntercept(`${conversationApiHost}/conversations/close-conversation`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify({
        ConversationId: conversationId,
      }),
    });
  if (result.success) {
    if (histories[groupChatId] != null) {
      histories[groupChatId] = []; // Clear cache on conversation close
    }
    return;
  }
  throw Error(`closeConversation failed with message:${result.message ?? 'n/a'}`);
}

export async function getGroupChatHistory(groupChatId:string, conversation:Conversation, page:number, pageSize:number) {
  // do we have enough cache to satisfy the request?
  if ((histories[groupChatId] == null) || (histories[groupChatId].length <= (page * pageSize))) {
    if (histories[groupChatId] == null) {
      histories[groupChatId] = [];
    }
    // get more data
    const resp = await streamGroupChatHistory(groupChatId, page, pageSize);
    if (resp.success && resp.messages != null) {
      // get agent infos
      const agentIds:Array<string> = [];
      resp.messages.forEach((message:any) => {
        if ((message.sendingAgentId != null) && (agentIds.indexOf(message.sendingAgentId) === -1)) {
          agentIds.push(message.sendingAgentId);
        }
      });
      let fullAgents:Array<Agent> = [];
      if (agentIds.length > 0) {
        fullAgents = await getAgentsByAgentIds(agentIds);
      }
      for (let i = 0; i < resp.messages.length; i++) {
        const mappedMessage = mapApiMessageToMessage(resp.messages[i], conversation, fullAgents);
        if (mappedMessage != null) {
          addNewMessageToCache(groupChatId, mappedMessage);
        }
      }
    }
  }
  return histories[groupChatId].slice((page * pageSize) - pageSize, page * pageSize);
}

export async function sendMessage(groupChatId:string, customerLineId:string, customerLineNumber:string, destinations:Array<string>, body:string, attachmentUrls:Array<string>):Promise<acxessEnqueueResponse> {
  const result = await fetchWithIntercept(`${conversationApiHost}/messages/send`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify({
        GroupChatId: groupChatId,
        CustomerLineId: customerLineId,
        Destinations: destinations,
        Body: body,
        AttachmentUrls: attachmentUrls,
      }),
    });
  if (result.success) {
    // add new message to the cache
    const newMessage = {
      status: MessageStatus.Sending,
      id: result.enqueueResponse.queuedMessageId,
      body,
      attachmentUrls,
      inbound: false,
      author: {
        lineId: customerLineId,
        mobileNumber: customerLineNumber,
        publicId: globalStore.getters['auth/agentId'],
        firstName: globalStore.getters['auth/session'].given_name,
        lastName: globalStore.getters['auth/session'].family_name,
      },
      sent: new Date(),
    };
    addNewMessageToCache(groupChatId, newMessage);
    return result.enqueueResponse;
  }
  throw Error(`sendMessage failed with message:${result.message ?? 'n/a'}`);
}

export async function flagGroupChat(groupChatId:string, isFlagged:boolean):Promise<void> {
  const result = await fetchWithIntercept(`${conversationApiHost}/conversations/flag-groupchat`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify({
        GroupChatId: groupChatId,
        IsFlagged: isFlagged,
      }),
    });
  if (result.success) {
    return;
  }
  throw Error(`flagGroupChat failed with message:${result.message ?? 'n/a'}`);
}

export async function nameGroupChat(groupChatId:string, newName:string):Promise<void> {
  const result = await fetchWithIntercept(`${conversationApiHost}/conversations/name-groupchat`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify({
        GroupChatId: groupChatId,
        Name: newName.trim(),
      }),
    });
  if (result.success) {
    return;
  }
  throw Error(`Name group chat failed with message:${result.message ?? 'n/a'}`);
}

export async function scheduleMessages(outboundLineId: string, destinations: Array<string>, messageBody: string, attachmentUrl: string, sendTime: Date): Promise<void> {
  const result = await fetchWithIntercept(`${conversationApiHost}/schedule/schedule-messages`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify({
        OutboundLineId: outboundLineId,
        Destinations: destinations,
        MessageBody: messageBody,
        AttachmentUrl: attachmentUrl,
        SendTime: sendTime,
      }),
    });
  if (result.success) {
    return;
  }
  throw Error(`Failed to schedule messages with error message:${result.message ?? 'n/a'}`);
}

export async function getScheduledMessages(customerLineIds: Array<string>, statuses: Array<number>, conversationOnly: boolean): Promise<Array<scheduledMessage>> {
  const result = await fetchWithIntercept(`${conversationApiHost}/schedule/get-scheduled-messages`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify({
        CustomerLineIds: customerLineIds,
        Statuses: statuses,
        ConversationOnly: conversationOnly,
      }),
    });
  if (result.success) {
    return result.scheduledMessages;
  }
  throw Error(`Failed to get scheduled messages with error message:${result.message ?? 'n/a'}`);
}

export async function updateScheduledMessage(scheduledMessageId: string | null, outboundLineId: string | null, destination: string | null,
  messageBody: string | null, attachmentUrl: string | null, sendTime: Date | null): Promise<string> {
  const result = await fetchWithIntercept(`${conversationApiHost}/schedule/update-scheduled-message`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify({
        ScheduledMessageId: scheduledMessageId,
        OutboundLineId: outboundLineId,
        Destination: destination,
        MessageBody: messageBody,
        AttachmentUrl: attachmentUrl,
        SendTime: sendTime,
      }),
    });
  if (result.updatedSuccessfully) {
    return result.updatedSuccessfully;
  }
  throw Error(`Failed to update scheduled message with error message:${result.message ?? 'n/a'}`);
}

export async function cancelScheduledMessage(scheduledMessageId: string): Promise<void> {
  const result = await fetchWithIntercept(`${conversationApiHost}/schedule/cancel-scheduled-message/${scheduledMessageId}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
    });
  if (result.success) {
    return;
  }
  throw Error(`Failed to cancel scheduled message with error message:${result.message ?? 'n/a'}`);
}

export async function getNamedGroupChats(lineIds: Array<string>): Promise<Array<GroupChat>> {
  const result = await fetchWithIntercept(`${conversationApiHost}/conversations/named-groupchats`,
    {
      method: 'Post',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify({
        CustomerLineIds: lineIds,
      }),
    });

  if (result.success) {
    return result.groupChats;
  }
  return [] as Array<GroupChat>;
}

function showBrowserNewMessageNotification(groupChatId:string, mappedMessage:Message):void {
  // if the user isn't in the chat for this groupchat, or the document doesn't have focus - alert them
  if (!document.hasFocus() || (groupChatId !== globalStore.getters['conversations/activeGroupChatId'])) {
    if (globalStore.getters['app/appNotificationsEnabled']) {
      const source = (acxessContactHasName(mappedMessage.author!)) ? acxessContactName(mappedMessage.author!) : mappedMessage.author!.mobileNumber;
      showNotification(`Textel - New message from ${source}`, mappedMessage.body, (isNullOrEmpty(mappedMessage.attachmentUrls)) ? undefined : mappedMessage.attachmentUrls![0]);
      globalStore.dispatch('conversations/setUnreadGroupChatId', {
        groupChatId,
        isRead: false,
      });
      if (!document.title.includes('New Message')) {
        blinkTitle('New Message');
      }
    }
  }
}

async function onEventNewMessage(payload: any): Promise<void> {
  let fullAgents:Array<Agent> = [];
  if (payload.message.sendingAgentId != null) {
    fullAgents = await getAgentsByAgentIds([payload.message.sendingAgentId]);
  }
  const mappedConvo = mapEventConversationToConversation(payload.conversation, payload.contacts, payload.customerLine, payload.message.created);
  const mappedMessage = mapApiMessageToMessage(payload.message, mappedConvo, fullAgents);
  if (mappedMessage == null) {
    return;
  }
  // update cache if we have it
  const { groupChatId } = payload.conversation;
  if (histories[groupChatId] != null && !payload.message.isDeleted) {
    addNewMessageToCache(groupChatId, mappedMessage);
  }
  EventBus.$emit(Channels.convoNewMessageMapped, mappedMessage, mappedConvo);
  // don't show new message notification if is bot session
  if (!mappedConvo.isBotSession) {
    showBrowserNewMessageNotification(groupChatId, mappedMessage);
  }
}

async function onEventNewOutboundMessage(payload: any): Promise<void> {
  if (payload.conversation == null) return;
  let fullAgents:Array<Agent> = [];
  if (payload.message.sendingAgentId != null) {
    fullAgents = await getAgentsByAgentIds([payload.message.sendingAgentId]);
  }
  const mappedConvo = mapEventConversationToConversation(payload.conversation, payload.contacts, payload.customerLine, payload.message.created);
  const mappedMessage = mapApiMessageToMessage(payload.message, mappedConvo, fullAgents);
  if (mappedMessage == null) {
    return;
  }
  // update cache if we have it
  const { groupChatId } = payload.conversation;
  addNewMessageToCache(groupChatId, mappedMessage);
  EventBus.$emit(Channels.convoNewMessageMapped, mappedMessage, mappedConvo);
}

function onEventMessageStatusChanged(payload:any):void {
  if (histories[payload.groupChatId] == null) {
    return;
  }
  const foundMessage = histories[payload.groupChatId].find((x:Message) => x.id === payload.messageId);
  if (foundMessage != null) {
    foundMessage.delivered = new Date();
    foundMessage.status = payload.newMessageStatus;
  } else {
    // the BE sent the message before returning from the send API call - set a timer to retry the status update
    setTimeout(() => {
      const messageRetry = histories[payload.groupChatId].find((x:Message) => x.id === payload.messageId);
      if (messageRetry != null) {
        messageRetry.delivered = new Date();
        messageRetry.status = payload.newMessageStatus;
      }
    }, 5000);
  }
}

function onEventConvoStatus(payload:any): void {
  // only processing new (or re-open), for notifications, Conversations.vue does additional processing
  if (payload.status !== ConversationSearchStatusFlags.Open) {
    return;
  }
  const mappedConvo = mapEventConversationToConversation(payload.conversation, payload.contacts, payload.customerLine, payload.message?.created);
  if (payload.message != null) {
    const mappedMessage = mapApiMessageToMessage(payload.message, mappedConvo);
    if (mappedMessage == null) {
      logAcxessException(logType.Error, 'Could not parse convo status signalr event in acxess-conversations.');
      return;
    }
    // don't show new message notification if is bot session
    if (!mappedConvo.isBotSession) {
      showBrowserNewMessageNotification(payload.conversation.groupChatId, mappedMessage);
    }
  }
}

EventBus.$on(Channels.convoNewInboundMessage, onEventNewMessage);
EventBus.$on(Channels.convoNewOutboundMessage, onEventNewOutboundMessage);
EventBus.$on(Channels.convoMessageStatusChanged, onEventMessageStatusChanged);
EventBus.$on(Channels.convoStatusChange, onEventConvoStatus);
