
import Vue, { PropType } from 'vue';
import { allowedRailRoutes } from '@/router';
import { clearServiceCache } from '@/services';
import { logout } from '@/services/acxess';
import { acxessFeatureAccess, acxessProductIds } from '@/models/acxess';
import FeatureToggle from './FeatureToggle.vue';

export default Vue.extend({
  name: 'NavigationMenu',
  components: { FeatureToggle },
  props: {
    headerRoutes: {
      type: Array as PropType<Array<any>>,
    },
  },
  data() {
    return {
      allowedRoutes: [] as Array<any>,
      drawer: false,
      PaymentsPermissionsSet: {
        productId: acxessProductIds.Payments,
        accessLevels: [
          acxessFeatureAccess.View,
          acxessFeatureAccess.ViewAndChange,
          acxessFeatureAccess.ViewAndExecute,
          acxessFeatureAccess.ViewAndChangeAndExecute,
        ],
      },
    };
  },
  async mounted() {
    const railRoutes = await allowedRailRoutes();
    let routeId = this.headerRoutes.length + 1;
    for (let i = 0; i < railRoutes.length; i++) {
      this.allowedRoutes.push({
        id: routeId++,
        route: railRoutes[i],
      });
    }
    this.allowedRoutes = this.allowedRoutes.concat([...this.headerRoutes]);
  },
  methods: {
    routeTitle(route: any): string {
      if (route.route.meta.nav.header != null) {
        return route.route.meta.nav.header.title;
      }
      return route.route.meta.nav.rail.title;
    },
    routePath(route: any): string {
      if (route.route != null) {
        return route.route.path;
      }
      return '/';
    },
    routeIcon(route: any): string {
      if (route.route.meta.nav.header != null) {
        return route.route.meta.nav.header.icon;
      }
      return route.route.meta.nav.rail.icon;
    },
    async logout(): Promise<void> {
      const result = await logout();
      if (result.loggedOutSuccessfully) {
        clearServiceCache();
        this.$store.dispatch('auth/logout');
        this.$router.replace('/login');
      }
    },
  },
});
