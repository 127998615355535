import Vue from 'vue';
import Vuex from 'vuex';
import { acxessAuth } from './acxessAuth';
import { acxessConversations } from './acxessConversations';
import { appStore } from './appStore';
import { acxessIntegrations } from './acxessIntegrations';
import { filters } from './filters';

Vue.use(Vuex);

const globalStore = new Vuex.Store({
  modules: {
    auth: acxessAuth,
    conversations: acxessConversations,
    integrations: acxessIntegrations,
    app: appStore,
    filters,
  },
});

export default globalStore;

// init things
globalStore.dispatch('auth/startup');
globalStore.dispatch('app/startup');
globalStore.dispatch('integrations/startup');
