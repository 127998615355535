export const LocalStorageKeys = {
  appNotificationsEnabled: 'app-notifications-enabled',
  userToken: 'user-token',
  userAgentId: 'agent-id',
  accountTimezone: 'account-timezone',
  userSignature: 'user-signature',
  absoluteDateTimeDisplayEnabled: 'absolute-date-time-display-enabled',
  externalAgentId: 'external-agent-id',
  allowedLines: 'allowed-lines',
  integrationFormStartNewConversation: 'integration-form-start-new-conversation',
  genesysAccessToken: 'genesys-access-token',
  accountCustomDomain: 'account-custom-domain',
};

export enum AcxessAPIErrorCode {
  Unknown = 0,
  DestinationMatchesCustomerLine = 1,
  ExternalConversationAlreadyExists = 2,
  ConversationIsActiveBotSession = 3,
  LineHasChatBotResponders = 4,
  ContactOptedOut = 5,
  ContactNotTextEnabled = 6,
  FailedCustomDomainVerification = 7,
}

// keep all error messages here, will translate later
export const AcxessAPIErrorMessages = [{
  code: AcxessAPIErrorCode.DestinationMatchesCustomerLine,
  message: 'Cannot create a conversation with your own outbound line.',
}, {
  code: AcxessAPIErrorCode.ExternalConversationAlreadyExists,
  message: 'An active conversation already exists with the provided destination phone number(s).',
}, {
  code: AcxessAPIErrorCode.ConversationIsActiveBotSession,
  message: 'This contact is already engaged in an active Bot session. To interrupt that session, search for and claim the conversation to end the session and send your message.',
}, {
  code: AcxessAPIErrorCode.LineHasChatBotResponders,
  message: 'Cannot disable Bot routing until all Keyword Responder settings have been removed. Please go to Keywords and remove Bot Configurations from any of the active responders.',
}, {
  code: AcxessAPIErrorCode.ContactOptedOut,
  message: 'Conversation could not be created. One or more of the recipients has opted out of receiving text messages.',
}, {
  code: AcxessAPIErrorCode.ContactNotTextEnabled,
  message: 'Conversation could not be created. One or more recipients has a line that is not text enabled.',
}, {
  code: AcxessAPIErrorCode.FailedCustomDomainVerification,
  message: 'Unable to verify domain. Until verified, it cannot be used for short links. Please confirm your domain forwarding is set up or wait for it to propagate and try again.',
}];
