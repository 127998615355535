import { LocalStorageKeys } from '@/keys';

export const appStore = {
  namespaced: true,
  state: {
    appNotificationsEnabled: false,
    absoluteDateTimeDisplayEnabled: false,
  },
  mutations: {
    appNotificationsEnabled(state:any, isEnabled:boolean):void {
      state.appNotificationsEnabled = isEnabled;
    },
    absoluteDateTimeDisplayEnabled(state: any, isEnabled:boolean): void {
      state.absoluteDateTimeDisplayEnabled = isEnabled;
    },
  },
  getters: {
    appNotificationsEnabled: (state: any) => state.appNotificationsEnabled,
    absoluteDateTimeDisplayEnabled: (state: any) => state.absoluteDateTimeDisplayEnabled,
  },
  actions: {
    startup: (context: any) => {
      const rawAppNotificationSetting = localStorage.getItem(LocalStorageKeys.appNotificationsEnabled);
      if (rawAppNotificationSetting == null) {
        if ('Notification' in window) {
          if (Notification.permission === 'granted') {
            localStorage.setItem(LocalStorageKeys.appNotificationsEnabled, 'true');
            context.commit('appNotificationsEnabled', true);
          }
        }
      } else {
        context.commit('appNotificationsEnabled', rawAppNotificationSetting === 'true');
      }
      const rawAbsoluteTimeSetting = localStorage.getItem(LocalStorageKeys.absoluteDateTimeDisplayEnabled);
      if ((rawAbsoluteTimeSetting != null) && (rawAbsoluteTimeSetting === 'true')) {
        context.commit('absoluteDateTimeDisplayEnabled', true);
      } else {
        // default to false
        context.commit('absoluteDateTimeDisplayEnabled', false);
      }
    },
    appNotificationsEnabled: (context:any, params: { isEnabled:boolean }) => {
      localStorage.setItem(LocalStorageKeys.appNotificationsEnabled, (params.isEnabled) ? 'true' : 'false');
      context.commit('appNotificationsEnabled', params.isEnabled);
    },
    absoluteDateTimeDisplayEnabled: (context: any, params: { isEnabled:boolean }) => {
      localStorage.setItem(LocalStorageKeys.absoluteDateTimeDisplayEnabled, (params.isEnabled) ? 'true' : 'false');
      context.commit('absoluteDateTimeDisplayEnabled', params.isEnabled);
    },
  },
};
