export const locales:any = {
  en: {
    sending: 'Sending',
    delivered: 'Delivered',
    read: 'Read',
    received: 'Received',
    failed: 'Failed',
    optedout: 'Opted-Out',
    lastMessageSent: 'Last message',
  },
  es: {
    sending: 'Enviando',
    delivered: 'Entregado',
    read: 'Leido',
    received: 'Recibido',
    failed: 'Ha Fallado',
    optedout: 'Excluido',
    lastMessageSent: 'Último mensaje',
  },
  fr: {
    sending: 'Envoi en cours',
    delivered: 'Livré',
    read: 'Lire',
    received: 'Admis',
    failed: 'Manqué',
    optedout: 'Choisi de se Retirer',
    lastMessageSent: 'Dernier message',
  },
};
