import { LocalStorageKeys } from '@/keys';

export const acxessIntegrations = {
  namespaced: true,
  state: {
    externalAgentId: '',
    allowedLines: [],
    startNewConversation: true,
    genesysAccessToken: null,
  },
  mutations: {
    externalAgentId(state:any, externalAgentId:string):void {
      state.externalAgentId = externalAgentId;
    },
    allowedLines(state:any, allowedLines:Array<string>):void {
      state.allowedLines = allowedLines;
    },
    startNewConversation(state:any, startNewConversation:boolean):void {
      state.startNewConversation = startNewConversation;
    },
    genesysAccessToken(state:any, genesysAccessToken:string):void {
      state.genesysAccessToken = genesysAccessToken;
    },
  },
  getters: {
    externalAgentId: (state:any) => state.externalAgentId,
    allowedLines: (state:any) => state.allowedLines,
    startNewConversation: (state:any) => state.startNewConversation,
    genesysAccessToken: (state:any) => state.genesysAccessToken,
  },
  actions: {
    startup: (context:any) => {
      context.commit('externalAgentId', localStorage.getItem(LocalStorageKeys.externalAgentId) || '');

      const startNewConversationLocalValue = localStorage.getItem(LocalStorageKeys.integrationFormStartNewConversation);
      if (startNewConversationLocalValue) {
        context.commit('startNewConversation', startNewConversationLocalValue === 'true');
      }

      const genesysAccessToken = localStorage.getItem(LocalStorageKeys.genesysAccessToken);
      if (genesysAccessToken) {
        context.commit('genesysAccessToken', genesysAccessToken);
      }

      const allowedLinesLocalValue = localStorage.getItem(LocalStorageKeys.allowedLines);
      if (allowedLinesLocalValue) {
        context.commit('allowedLines', JSON.parse(allowedLinesLocalValue));
      }
    },
    externalAgentId: (context:any, params: { externalAgentId: string; }) => {
      localStorage.setItem(LocalStorageKeys.externalAgentId, params.externalAgentId);
      context.commit('externalAgentId', params.externalAgentId);
    },
    allowedLines: (context:any, params: { allowedLines: Array<string>; }) => {
      localStorage.setItem(LocalStorageKeys.allowedLines, JSON.stringify(params.allowedLines));
      context.commit('allowedLines', params.allowedLines);
    },
    startNewConversation: (context:any, params: { startNewConversation: boolean; }) => {
      localStorage.setItem(LocalStorageKeys.integrationFormStartNewConversation, params.startNewConversation.toString());
      context.commit('startNewConversation', params.startNewConversation);
    },
    genesysAccessToken: (context:any, params: { genesysAccessToken: string; }) => {
      localStorage.setItem(LocalStorageKeys.genesysAccessToken, params.genesysAccessToken);
      context.commit('genesysAccessToken', params.genesysAccessToken);
    },
  },
};
