import { acxessContact, acxessEnqueueResponse } from '@/models/acxess';
import { localTimeFromUTC } from '@/utils';
import MessageModel, { MessageStatus } from './message';
import Agent from './agent';

enum ConversationStatus {
    New = 1,
    Owned,
    Expired,
    Violation
}

enum ConversationSearchStatusFlags {
  None = 0,
  Unclaimed = 1,
  Claimed = 1 << 1,
  Open = 1 << 2,
  Closed = 1 << 3,
  Flagged = 1 << 4,
  BotSession = 1 << 5,
  NotBotSession = 1 << 6,
}

function StatusColorClass(convoStatus:ConversationStatus, classPrefix = '', classSuffix = ''):string {
  const cleanPrefix:string = classPrefix ?? '';
  const cleanSuffix:string = classSuffix ?? '';
  switch (convoStatus) {
    case ConversationStatus.New:
      return `${cleanPrefix}success${cleanSuffix}`;
    case ConversationStatus.Owned:
      return `${cleanPrefix}primary${cleanSuffix}`;
    case ConversationStatus.Violation:
      return `${cleanPrefix}error${cleanSuffix}`;
    case ConversationStatus.Expired:
      return `${cleanPrefix}info${cleanSuffix}`;
    default:
      return '';
  }
}

interface Conversation {
    id: string,
    groupChatId: string,
    messages: Array<MessageModel>,
    author: acxessContact,
    status: ConversationStatus,
    new_message_count: number,
    active_typing: boolean,
    last_message: MessageModel,
    owner?: Agent,
    wiid?: string,
    line_phone_number: string,
    line_name?: string,
    line_id?: number,
    line_uid?: string,
    end_date?: Date,
    start_date: Date,
    contacts: Array<acxessContact>,
    lineIdExcludedMapping: Record<string, boolean>,
    customerLines: Array<acxessContact>,
    followUpFlagged?: boolean,
    enqueueResponse?: acxessEnqueueResponse,
    newMessage?: string,
    newAttachments?: Array<string>,
    name?: string,
    isBotSession: boolean,
}

export function mapApiMessageToMessage(apiMessage: any, conversation: Conversation, agents?:Array<Agent>): MessageModel | null {
  // match the creator line to get the author of the message
  const contact:acxessContact | undefined = conversation.contacts.find((x:any) => x.lineId === apiMessage.creatorLineId);
  const lineContact:acxessContact | undefined = conversation.customerLines.find((x:any) => x.lineId === apiMessage.creatorLineId);
  let mappedMessage:MessageModel | null = null;
  if ((contact == null) && (lineContact == null)) {
    console.error('Could not match message creatorLineId to line or list of conversation contacts.');
  } else {
    // map the attachment urls
    let attachmentUrls:Array<string> = [];
    if (apiMessage.messageAttachments != null) {
      attachmentUrls = apiMessage.messageAttachments.map((x:any) => x.url);
    }
    // match agent if present - we need lineContact also for the mobile number
    let sendingAgent:acxessContact | undefined;
    if ((agents != null) && (agents.length > 0) && (lineContact != null)) {
      const foundAgent = agents.find((x:Agent) => x.id === apiMessage.sendingAgentId);
      if (foundAgent != null) {
        sendingAgent = {
          id: foundAgent.id,
          firstName: foundAgent.firstName,
          lastName: foundAgent.lastName,
          mobileNumber: lineContact!.mobileNumber,
          publicId: foundAgent.publicId!,
        };
      }
    }
    mappedMessage = {
      id: apiMessage.id,
      status: apiMessage.status || apiMessage.messageStatus,
      body: apiMessage.fullBody,
      inbound: !apiMessage.isOutgoing,
      attachmentUrls,
      author: apiMessage.author || contact || sendingAgent || lineContact,
      isDeleted: apiMessage.isDeleted,
      sent: localTimeFromUTC(new Date(apiMessage.created)),
      received: localTimeFromUTC(new Date(apiMessage.created)),
      productId: apiMessage.productId,
      hasTranslation: apiMessage.hasTranslation,
    };
  }
  return mappedMessage;
}

export function mapApiConversationToConversation(apiConvo: any): Conversation {
  // support different BE return shapes
  let apiContacts = apiConvo.contacts;
  if (apiContacts == null || (apiContacts.length > 0 && apiContacts[0] == null)) {
    apiContacts = apiConvo.conversation.contacts;
  }
  let apiCustomerLines = apiConvo.customerLines;
  if (apiCustomerLines == null || (apiCustomerLines.length > 0 && apiCustomerLines[0] == null)) {
    apiCustomerLines = apiConvo.conversation.customerLines;
  }
  const line_phone_number = (apiCustomerLines[0].phoneNumber != null) ? apiCustomerLines[0].phoneNumber : apiConvo.line_phone_number;
  const line_name = (apiCustomerLines[0].lineName != null) ? apiCustomerLines[0].lineName : apiConvo.line_name;
  let status:ConversationStatus = ConversationStatus.New;
  let owner:Agent | undefined;
  if (apiConvo.conversation.agent != null) {
    status = ConversationStatus.Owned;
    owner = {
      id: apiConvo.conversation.agent.agentId,
      firstName: apiConvo.conversation.agent.firstName,
      lastName: apiConvo.conversation.agent.lastName,
    };
  }
  let apiEnqueueResponse = null;
  if (apiConvo.conversation.enqueueResponse != null) {
    apiEnqueueResponse = apiConvo.conversation.enqueueResponse;
  }
  const customerLines:Array<acxessContact> = apiCustomerLines.map((x:any) => ({
    id: x.id,
    lineId: x.lineId,
    mobileNumber: x.phoneNumber,
  }));
  return {
    line_phone_number,
    line_name,
    owner,
    status,
    groupChatId: apiConvo.conversation.groupChatId,
    id: apiConvo.conversation.id,
    line_uid: apiCustomerLines[0].lineId,
    new_message_count: 0,
    messages: [],
    author: {
      publicId: apiContacts[0]?.publicId,
      mobileNumber: apiContacts[0]?.mobileNumber,
      firstName: apiContacts[0]?.firstName,
      lastName: apiContacts[0]?.lastName,
    },
    active_typing: false,
    end_date: (apiConvo.conversation.ended != null) ? localTimeFromUTC(new Date(apiConvo.conversation.ended)) : undefined,
    start_date: localTimeFromUTC(new Date(apiConvo.conversation.started)),
    last_message: {
      sent: localTimeFromUTC(new Date(apiConvo.lastMessageTime)),
      received: localTimeFromUTC(new Date(apiConvo.lastMessageTime)),
      status: MessageStatus.Delivered,
      body: apiConvo.lastMessageSnip,
    },
    contacts: apiContacts,
    lineIdExcludedMapping: apiConvo.lineIdExcludedMapping,
    customerLines,
    followUpFlagged: apiConvo.followUpFlagged,
    name: apiConvo.name,
    enqueueResponse: apiEnqueueResponse,
    isBotSession: apiConvo.conversation.isBotSession,
  };
}

export function mapEventConversationToConversation(convo: any, contacts: Array<any>, customerLine: any, lastMessageTime?: Date) {
  return mapApiConversationToConversation({
    conversation: convo,
    contacts,
    customerLines: [customerLine],
    lastMessageTime,
  });
}

export default Conversation;
export { ConversationStatus, StatusColorClass, ConversationSearchStatusFlags };
