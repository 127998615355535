import * as signalR from '@microsoft/signalr';
import { EventBus, Channels } from '@/eventbus';
import globalStore from '@/store/index';
import { isNullOrEmpty } from '@/utils';
import ExceptionInvalidToken from '@/models/exceptions/exceptionInvalidToken';
import { MessageStatus } from '@/models/conversations/message';
import { fetchWithIntercept } from '.';
import { logAcxessException, logType } from './logger';
import { buildAcxessAuthenticatedHeader } from './acxess';

const conversationApiHost:string = process.env.VUE_APP_CONVERSATION_API_URL!;
const messagehubUrl = process.env.VUE_APP_CONVERSATION_HUB_URL!;
let starting = false;
let stopping = false;
let messageHub:signalR.HubConnection | null = null;
let debounceId:any = null;

async function buildAccessToken():Promise<string> {
  const token:string = globalStore.getters['auth/token'];
  if (isNullOrEmpty(token)) {
    throw new ExceptionInvalidToken('invalid token');
  }
  // TODO: for now we are using the negotiate call to verify the current user token is good and handle 403s, etc...
  const resp = await fetchWithIntercept(`${messagehubUrl}/negotiate`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      ...buildAcxessAuthenticatedHeader(),
    },
  }, false, false);
  // return the latest token, in case the previous one needed to be updated by fetchWithIntercept
  return globalStore.getters['auth/token'];
}

async function addConnectionToLineGroups(connectionId:string):Promise<void> {
  const result = await fetchWithIntercept(`${conversationApiHost}/conversations/add-connection-to-line-groups`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...buildAcxessAuthenticatedHeader(),
      },
      body: JSON.stringify({
        ConnectionId: connectionId,
      }),
    });
  if (result.success) {
    return;
  }
  throw Error(`addConnectionToLineGroups failed with message:${result.message ?? 'n/a'}`);
}

async function onConnected():Promise<void> {
  if (messageHub != null && messageHub.connectionId != null) {
    await addConnectionToLineGroups(messageHub.connectionId);
    globalStore.dispatch('conversations/signalRConnected', {
      isConnected: true,
    });
    EventBus.$emit(Channels.signalRConnectionChanged, true);
    return;
  }
  logAcxessException(logType.Error, 'onConnected - hub and connection invalid');
}

function onReconnecting():void {
  logAcxessException(logType.Warning, 'onReconnecting invoked');
  globalStore.dispatch('conversations/signalRConnected', {
    isConnected: false,
  });
  EventBus.$emit(Channels.signalRConnectionChanged, false);
}

function broadcastConversationStatus(statusMessage: any): void {
  logAcxessException(logType.Info, 'received broadcastConversationStatus');
  EventBus.$emit(Channels.convoStatusChange, statusMessage);
}

function broadcastInfoMessage(infoMessage:any):void {
  logAcxessException(logType.Info, infoMessage.message);
}

// obsolete.. left here for backwards compatability - broadcasting the message status changed event instead
function broadcastMessageSent(payload:any):void {
  logAcxessException(logType.Info, 'received broadcastMessageSent');
  const statusPayload = {
    groupChatId: payload.groupChatId,
    messageId: payload.messageId,
    newMessageStatus: MessageStatus.Delivered,
  };
  EventBus.$emit(Channels.convoMessageStatusChanged, statusPayload);
}

function broadcastMessageNew(payload:any):void {
  logAcxessException(logType.Info, 'received broadcastMessageNew');
  EventBus.$emit(Channels.convoNewInboundMessage, payload);
}

function broadcastMessageOutbound(payload: any): void {
  logAcxessException(logType.Info, 'received broadcastMessageOutbound');
  EventBus.$emit(Channels.convoNewOutboundMessage, payload);
}

function broadcastGroupChatFlagged(payload:any):void {
  logAcxessException(logType.Info, 'received broadcastGroupChatFlagged');
  EventBus.$emit(Channels.convoGroupChatFlagged, payload);
}

function broadcastGroupChatNamed(payload:any):void {
  logAcxessException(logType.Info, 'received broadcastGroupChatNamed');
  EventBus.$emit(Channels.convoGroupChatNamed, payload);
}

function broadcastMessageStatusChanged(payload:any):void {
  logAcxessException(logType.Info, 'received broadcastMessageStatusChanged');
  EventBus.$emit(Channels.convoMessageStatusChanged, payload);
}

function broadcastBotSessionStarted(payload: any): void {
  logAcxessException(logType.Info, 'received broadcastBotSessionStarted');
  EventBus.$emit(Channels.botSessionStarted, payload);
}

function broadcastBotSessionEnded(payload: any): void {
  logAcxessException(logType.Info, 'received broadcastBotSessionEnded');
  EventBus.$emit(Channels.botSessionEnded, payload);
}

async function startMessageHub():Promise<void> {
  if (starting) return;
  clearTimeout(debounceId);
  debounceId = setTimeout(async () => {
    if ((starting) || (messageHub != null && messageHub.connectionId != null && messageHub.state === signalR.HubConnectionState.Connected)) {
      return;
    }
    try {
      messageHub = new signalR.HubConnectionBuilder()
        .withUrl(messagehubUrl, {
          accessTokenFactory: buildAccessToken,
        })
        .withStatefulReconnect()
        .withAutomaticReconnect()
        .configureLogging(signalR.LogLevel.Information)
        .build();
      messageHub.on('broadcastInfoMessage', (infoPayload: any) => broadcastInfoMessage(infoPayload));
      messageHub.on('broadcastConversationStatus', (statusPayload: any) => broadcastConversationStatus(statusPayload));
      messageHub.on('broadcastMessageNew', (newMessagePayload: any) => broadcastMessageNew(newMessagePayload));
      messageHub.on('broadcastMessageOutbound', (outboundMessagePayload: any) => broadcastMessageOutbound(outboundMessagePayload));
      messageHub.on('broadcastMessageSent', (messageSentPayload:any) => broadcastMessageSent(messageSentPayload));
      messageHub.on('broadcastGroupChatFlagged', (groupChatFlaggedPayload: any) => broadcastGroupChatFlagged(groupChatFlaggedPayload));
      messageHub.on('broadcastGroupChatNamed', (groupChatNamedPayload: any) => broadcastGroupChatNamed(groupChatNamedPayload));
      messageHub.on('broadcastMessageStatusChanged', (messageStatusChangedPayload: any) => broadcastMessageStatusChanged(messageStatusChangedPayload));
      messageHub.on('broadcastBotSessionStarted', (botSessionStartedPayload: any) => broadcastBotSessionStarted(botSessionStartedPayload));
      messageHub.on('broadcastBotSessionEnded', (botSessionEndedPayload: any) => broadcastBotSessionEnded(botSessionEndedPayload));
      messageHub.onreconnected(onConnected);
      messageHub.onreconnecting(onReconnecting);
      await messageHub.start();
      await onConnected();
    } catch (e) {
      logAcxessException(logType.Warning, e);
    } finally {
      starting = false;
    }
  }, 750);
}

export async function stopMessageHub():Promise<void> {
  if (messageHub == null || stopping) return;
  stopping = true;
  try {
    await messageHub.stop();
  } finally {
    stopping = false;
  }
}

export async function streamGroupChatHistory(groupChatId:string, page:number, pageSize:number):Promise<any> {
  if (messageHub != null) {
    return messageHub?.invoke('GetMessageHistory', groupChatId, page, pageSize);
  }
  throw new Error('error getting conversation history');
}

EventBus.$on(Channels.login, startMessageHub);
EventBus.$on(Channels.logout, stopMessageHub);
startMessageHub();
