import { AcxessAPIErrorCode } from '@/keys';

export default class AcxessException {
  message: string;
  code: AcxessAPIErrorCode;
  data: any;

  constructor(message:string, code: AcxessAPIErrorCode = AcxessAPIErrorCode.Unknown, data: any = null) {
    this.message = message;
    this.code = code;
    this.data = data;
  }
}
